import React from 'react'
import _ from 'lodash'

import { useSettings } from '../hooks/settingsContext'
import IconField from '../components/iconField'
import BuildSubPolicyLink from '../components/webChat/components/buildSubPolicyLink'

import './_signup.scss'

const PrivacyPolicy = () => {
  const { messages } = useSettings()

  const paragraphClass = 'flex flex-col gap-16'
  const HeaderClass = 'flex flex-col w-100'
  const subClass = 'flex flex-col w-100 gap-6'
  const grandsubClass = 'pl-8'
  const grand2subClass = 'pl-16'
  const titleClass = 'large'
  const subTitleClass = 'small'

  const effectedDate = 'Effective Date: December 5 2024'

  return (
    <div className="site-layout scrollbar overflow-y">
      <div className="terms-container policy flex flex-col align-center">
        <div className="flex flex-col align-center gap-56 pb-40">
          <div className="flex flex-col align-center gap-20">
            <IconField name="logoM" />
            <div className="flex flex-col align-center gap-6">
              <div className='xlarge'>Privacy policy</div>
              <div className='small'>{effectedDate}</div>
            </div>
          </div>
          <div className="flex flex-col gap-40 w-100 p-20">
            <div>
              Welcome to Tellme AI! This Privacy Policy explains how Tellme AI (“we,” “our,” or “us”) collects, uses, discloses, and protects your personal information when you use our AI generative application. By using Tellme AI, you agree to the practices described in this Privacy Policy.
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  1. Information We Collect
                </div>
                <div className={subTitleClass}>
                  We collect several types of information to provide, improve, and secure our services:
                </div>
              </div>
              <div className={subClass}>
                <div className={grandsubClass}>
                  a. Personal Information
                </div>
                <div className={grand2subClass}>
                  Account Information: When you create an account, we may collect personal details, such as your name, email address, and contact information.
                </div>
                <div className={grand2subClass}>
                  Payment Information: For users who make purchases, we collect payment details, which are processed by a third-party payment provider. We do not store credit card information.
                </div>
              </div>
              <div className={subClass}>
                <div className={grandsubClass}>
                  b. Usage Information
                </div>
                <div className={grand2subClass}>
                  App Usage Data: We collect information about how you interact with our app, such as pages visited, actions taken, and content generated.
                </div>
                <div className={grand2subClass}>
                  Device Information: This includes IP address, browser type, operating system, and device identifiers.
                </div>
              </div>
              <div className={subClass}>
                <div className={grandsubClass}>
                  c. Content Data
                </div>
                <div className={grand2subClass}>
                  Generated Content: Any content generated through Tellme AI may be stored and used to improve our AI models, develop new features, and enhance service quality.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  2. How We Use Your Information
                </div>
                <div className={subTitleClass}>
                  We use the collected information for various purposes, including:
                </div>
              </div>
              <div className={grandsubClass}>
                Service Delivery: To operate, maintain, and enhance our app, including delivering personalized features.
              </div>
              <div className={grandsubClass}>
                Improvement and Development: To analyze usage patterns, enhance the AI’s accuracy, and develop new features.
              </div>
              <div className={grandsubClass}>
                Communication: To send you updates, security alerts, and notifications related to your account or our services.
              </div>
              <div className={grandsubClass}>
                Legal Compliance: To comply with legal obligations, resolve disputes, and enforce our Terms of Use.
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  3. Sharing Your Information
                </div>
                <div className={subTitleClass}>
                  We do not sell your personal information to third parties. However, we may share your information with:
                </div>
              </div>
              <div className={grandsubClass}>
                Service Providers: Third-party vendors who help with hosting, analytics, payment processing, and customer support.
              </div>
              <div className={grandsubClass}>
                Legal and Compliance: In response to legal requests, such as subpoenas or regulatory requirements, we may disclose information if required by law.
              </div>
              <div className={grandsubClass}>
                Corporate Transactions: In the event of a merger, acquisition, or sale, your data may be transferred to the acquiring entity.
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  4. Your Data Rights
                </div>
                <div className={subTitleClass}>
                  Depending on your location, you may have the following rights concerning your personal information:
                </div>
              </div>
              <div className={grandsubClass}>
                Access: Request access to your personal data.
              </div>
              <div className={grandsubClass}>
                Correction: Request correction of any inaccurate data.
              </div>
              <div className={grandsubClass}>
                Deletion: Request deletion of your personal data, subject to legal or contractual obligations.
              </div>
              <div className={grandsubClass}>
                Data Portability: Receive a copy of your data in a commonly used format.
              </div>
              <div className={grandsubClass}>
                Opt-Out of Marketing: Opt-out of receiving marketing communications by following the unsubscribe instructions.
              </div>
              <div className={grandsubClass}>
                To exercise your rights, please contact us at info@tellme.club.
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  5. Data Retention
                </div>
                <div className={subTitleClass}>
                  We retain your information only as long as necessary for the purposes outlined in this Privacy Policy or as required by law. Generated content may be stored anonymously for future improvement of our AI models.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  6. Security
                </div>
                <div className={subTitleClass}>
                  We implement reasonable security measures to protect your data from unauthorized access, alteration, or destruction. However, no data transmission or storage system is completely secure, and we cannot guarantee absolute security.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  7. Cookies and Tracking Technologies
                </div>
                <div className={subTitleClass}>
                  Our app uses cookies and similar tracking technologies to enhance user experience, analyze usage patterns, and deliver targeted ads. You can manage your cookie preferences in your browser settings.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  8. Children’s Privacy
                </div>
                <div className={subTitleClass}>
                  Tellme AI is not intended for users under the age of 13. We do not knowingly collect personal information from children under 13. If we discover that a child has provided us with personal information, we will delete it promptly.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  9. Changes to This Privacy Policy
                </div>
                <div className={subTitleClass}>
                  We may update this Privacy Policy from time to time. We will notify you of significant changes by updating the date at the top of the policy or through notifications within the app.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  10. Contact Us
                </div>
                <div className={subTitleClass}>
                  If you have any questions or concerns about this Privacy Policy, please contact us at info@tellme.club.
                </div>
              </div>
            </div>
          </div>
          <BuildSubPolicyLink />
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
