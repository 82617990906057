import React, { useRef, useState, useEffect } from 'react'
import _ from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'

import IconField from '../components/iconField'
import { useFlash } from '../hooks/useFlash'
import { UserApi, ChallengeAPI } from '../api/admin'
import { useAuth } from '../hooks/authProvider'
import useFormBehavior from '../hooks/useFormBehavior'
import { FLASH_TYPE, ACTION_OTP_TYPE } from '../common/constants'
import { useSettings } from '../hooks/settingsContext'
import TermsPolicy from '../components/webChat/components/termsPolicy'

import './_signup.scss'

const codeLength = 6
const initialState = Array.from({ length: codeLength }, (_, index) => ({
  [`confirmation_code${index}`]: '',
})).reduce((code, curr) => ({ ...code, ...curr }), {})

const Confirmation = ({ email, type, newPassword }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { messages } = useSettings()
  const flash = useFlash()
  const urlParams = new URLSearchParams(window.location.search)
  const emailAddress = email || urlParams.get('m')
  const confirmType = type || urlParams.get('t')
  const [loading, setLoading] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [seconds, setSeconds] = useState(120)

  const { password } = location?.state || ''
  const inputRefs = useRef([])
  const auth = useAuth()

  const fields = Array.from({ length: codeLength }, (_, index) => ({
    name: `confirmation_code${index}`,
    inputType: 'TextInput',
    validators: ['required', 'confirmation_code'],
    inputClassName: 'verify-item',
    labelError: messages['confirmationCode.code'],
  }))

  const {
    isFormValid,
    formData: code,
    setFormData: setCode,
    validateForm,
    errors,
    initFormData,
    handleChange: onChange,
    handleBlur,
  } = useFormBehavior({ fields })

  useEffect(() => {
    initFormData(initialState)
  }, [])

  const formatTime = (secs) => {
    const minutes = Math.floor(secs / 60)
    const seconds = secs % 60
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  const backToHome = () => {
    navigate('/chat')
    confirmType === 'signup' && auth.setIsLogout(true)
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
    onChange(e, index)
    if (value && index < 5) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handlePaste = (e, index) => {
    e.preventDefault()

    const pasteData = e.clipboardData.getData('text').slice(0, 6)
    const pasteArray = pasteData.split('').slice(0, 6 - index)
    const newValues = {}

    _.forEach(pasteArray, (p, i) => {
      const key = `confirmation_code${index + i}`
      newValues[key] = p
    })

    setCode((prevValues) => ({ ...prevValues, ...newValues }))

    setTimeout(() => {
      const nextIndex = Math.min(index + pasteArray.length, 5)
      inputRefs.current[nextIndex].focus()
    }, 0)

    validateForm({ ...code, ...newValues })
  }

  const handleKeyDown = (e, index) => {
    const key = `confirmation_code${index}`

    if (e.key === 'Backspace') {
      setCode((prev) => ({
        ...prev,
        [key]: '',
      }))

      if (index > 0 && !e.target.value) {
        inputRefs.current[index - 1].focus()
      }
    }

    if (e.keyCode === 13) {
      handleSubmit()
    }
  }

  const handleSignup = (confirm_code) => {
    const payload = {
      password,
      email: emailAddress,
      confirm_code,
    }

    UserApi.register({ payload }, (err, res) => {
      if (!err && res?.data) {
        auth.loginAction({
          password,
          user_name: emailAddress,
        })
        navigate('/chat')
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
      setLoading(false)
    })
  }

  const handleForgot = (confirm_code) => {
    const data = {
      email: emailAddress,
      new_password: newPassword,
      challenge_code: confirm_code,
    }

    UserApi.forgotPassword({ payload: data }, (err, res) => {
      if (!err) {
        flash.showFlash(FLASH_TYPE.Success, res)
        navigate('/login')
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
      setLoading(false)
    })
  }

  const handleDeleteAccount = (confirm_code) => {
    const payload = {
      is_third_party_login: false,
      challenge_code: confirm_code,
    }
    UserApi.deleteAccount({ payload }, (err, res) => {
      if (!err) {
        flash.showFlash(FLASH_TYPE.Success, res)
        auth.setIsLogout(true)
        auth.setUser({})
        navigate('/chat')
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
      setLoading(false)
    })
  }

  const handleSubmit = () => {
    if (
      !isFormValid ||
      _.some(Object.values(code).map((v) => _.isEmpty(v))) ||
      loading
    )
      return
    setLoading(true)
    let confirm_code = ''
    _.forEach(Object.values(code), (v) => (confirm_code += v))
    switch (confirmType) {
      case 'signup':
        handleSignup(confirm_code)
        break
      case 'forgot':
        handleForgot(confirm_code)
        break
      case 'delete':
        handleDeleteAccount(confirm_code)
        break
    }
  }

  const resendOTP = () => {
    let payload = {}
    if (loading) return
    setLoading(true)
    switch (confirmType) {
      case 'signup':
        payload = { email: emailAddress }
        ChallengeAPI.requestOTPRegister({ payload }, (err, res) => {
          if (!err && res?.data) {
            flash.showFlash(FLASH_TYPE.Success, res)
            setIsActive(true)
            setSeconds(120)
          } else {
            flash.showFlash(FLASH_TYPE.Error, err)
          }
          setLoading(false)
        })
        break
      case 'forgot':
        payload = {
          email: emailAddress,
          otp_type: ACTION_OTP_TYPE.forgotPasswordOtp,
        }

        ChallengeAPI.emailChallenges({ payload }, (err, res) => {
          if (!err) {
            flash.showFlash(FLASH_TYPE.Success, res)
            setIsActive(true)
            setSeconds(120)
          } else {
            flash.showFlash(FLASH_TYPE.Error, err)
          }
          setLoading(false)
        })
        break
      case 'delete':
        payload = {
          email: emailAddress,
          otp_type: ACTION_OTP_TYPE.deleteAccountOtp,
        }

        ChallengeAPI.emailChallenges({ payload }, (err, res) => {
          if (!err) {
            flash.showFlash(FLASH_TYPE.Success, res)
            setIsActive(true)
            setSeconds(120)
          } else {
            flash.showFlash(FLASH_TYPE.Error, err)
          }
          setLoading(false)
        })
        break
    }
  }

  const renderError = () => {
    let result = ''
    if (errors && Object.values(errors)) {
      Object.values(errors).forEach((err) => {
        if (err && !result) {
          result = err
        }
      })
    }
    return (
      result && (
        <div className="error-message">
          <p>{result}</p>
        </div>
      )
    )
  }

  const renderOTPMessage = () =>
    isActive ? (
      <span className="count-down text-secondary">
        {messages['confirmationCode.resendIn']} {formatTime(seconds)}{' '}
      </span>
    ) : (
      <p>
        {' '}
        {messages['confirmationCode.question']}{' '}
        <span className="code-message text-link" onClick={resendOTP}>
          {' '}
          {messages['confirmationCode.request']}{' '}
        </span>{' '}
      </p>
    )

  const onRef = (node) => {
    if (!node) return
    const isOverflowing = node.clientWidth !== node.scrollWidth
    if (isOverflowing) node.setAttribute('title', node.innerHTML)
    else node.removeAttribute('title')
  }

  useEffect(() => {
    let interval = null
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)
    } else if (seconds === 0) {
      setIsActive(false)
    }
    return () => clearInterval(interval)
  }, [isActive, seconds])

  return (
    <div className="site-layout">
      <div className="confirmation-container flex flex-col align-center">
        <div className="flex flex-col align-center gap-20">
          <IconField name="logoM" />
          <div className="flex flex-col align-center gap-32 w-100">
            <div className="flex flex-col align-center gap-4 w-100">
              <div className="verify-label">
                {messages['confirmationCode.title']}
              </div>
              <div className="text-secondary">
                {messages['confirmationCode.description']}
              </div>
              <span
                title={emailAddress}
                ref={onRef}
                className="email-verify truncate-text"
              >
                {emailAddress}
              </span>
            </div>
            <div className="flex flex-col align-center gap-16">
              <div className="verify-field flex gap-8">
                {Object.keys(code).map((key, index) => (
                  <input
                    name={`confirmation_code${index}`}
                    maxLength={1}
                    key={index}
                    type="text"
                    value={code[key]}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onChange={(e) => handleChange(e, index)}
                    onBlur={handleBlur}
                    onPaste={(e) => handlePaste(e, index)}
                    ref={(e) => (inputRefs.current[index] = e)}
                    className={`verify-item ${errors[`confirmation_code${index}`] && 'error'}`}
                  />
                ))}
              </div>
              {renderError()}

              <div className="flex flex-col align-center gap-16 w-100">
                <button
                  className="btn btn-primary btn-verify w-100"
                  disabled={
                    !isFormValid ||
                    _.some(Object.values(code).map((v) => _.isEmpty(v))) ||
                    loading
                  }
                  onClick={handleSubmit}
                >
                  {messages['confirmationCode.submit']}
                </button>
                <button
                  className="btn btn-secondary btn-back w-100"
                  onClick={backToHome}
                >
                  {messages['confirmationCode.back']}
                </button>
                {renderOTPMessage()}
              </div>
            </div>
          </div>
        </div>
        <TermsPolicy />
      </div>
    </div>
  )
}

export default Confirmation
