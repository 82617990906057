import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import CommonModal from '../modal'
import LoginWidget from '../../webChat/components/loginWidget'
import { useAuth } from '../../../hooks/authProvider'
import { useSettings } from '../../../hooks/settingsContext'

const WelcomeBackModal = (props) => {
  const { isLogout, setIsLogout } = useAuth()
  const { messages } = useSettings()

  const handleCloseModal = () => {
    setIsLogout(false)
  }

  return (
    <CommonModal
      isOpen={isLogout}
      id="welcome-back"
      name="welcome-back"
      modalClass="comeback-modal"
      element={
        <div className="flex flex-col gap-32">
          <div className="title">{messages['welcomeModal.title']}</div>
          <div className="flex flex-col gap-16">
            <div className="description text-secondary">
              {messages['welcomeModal.description']}
            </div>
            <LoginWidget isShow={true} />
            <span className="link-item c-pointer" onClick={handleCloseModal}>
              {messages['welcomeModal.linkStayLogout']}
            </span>
          </div>
        </div>
      }
      handleClose={handleCloseModal}
      noHeader={true}
      noFooter={true}
    />
  )
}

export default WelcomeBackModal
