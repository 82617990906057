import React, { useState } from 'react'

import CommonModal from '../modal'
import { ChallengeAPI, UserApi } from '../../../api/admin'
import ChatbotApi from '../../../api/chatbot/chatbotAPI'
import { useSettings } from '../../../hooks/settingsContext'
import { useNavigate } from 'react-router-dom'
import { useFlash } from '../../../hooks/useFlash'
import { useAuth } from '../../../hooks/authProvider'
import { ACTION_OTP_TYPE, FLASH_TYPE } from '../../../common/constants'
import _ from 'lodash'
import IconField from '../../iconField'

const SharingModal = ({ oldLink, converId, setIsOpenSharingModal, setCurrentChat }) => {
  const flash = useFlash()
  const [loading, setLoading] = useState(false)
  const { messages } = useSettings()
  const [generatedLink, setGeneratedLink] = useState('')
  const [isCopying, setIsCopying] = useState(false)

  const handleCloseModal = () => {
    setGeneratedLink('')
    setIsOpenSharingModal(false)
    if (generatedLink && generatedLink != oldLink) {
      setCurrentChat((prev) => ({
        ...prev,
        sharingLink: generatedLink
      }))
    }
  }

  const handleGenerateLink = () => {
    setLoading(true)
    ChatbotApi.generateSharingLink(
      converId,
      (err, res) => {
        if (!err && res?.data) {
          setGeneratedLink(res?.data)
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
        setLoading(false)
      }
    )
  }

  const handleShareFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${generatedLink}&amp;src=sdkpreparse`, "_blank");
  }

  const handleCopyLink = () => {
    setIsCopying(true)
    navigator.clipboard.writeText(generatedLink || oldLink)

    setTimeout(() => {
      setIsCopying(false)
    }, 1000)    
  }

  const handleRenderElement = () => {
    const isGenerated = !!generatedLink
    const isNeedGenerate = !oldLink
    const messageSuffix = isGenerated ? '.already' : ''
    const messagePrefix = isNeedGenerate ? '.generate' : '.update'

    const generateBtnClass = "generate-button btn btn-primary gap-8"
    const modalTitle = messages[`sharing.modal${messagePrefix}.title${messageSuffix}`]
    const modalDescription = isGenerated ? messages[`sharing.modal${messagePrefix}.description${messageSuffix}`] : messages["sharing.modal.description"]
    const generateButtonText = messages[`sharing.modal${messagePrefix}.buttonTxt`]
    const copyButtonText = messages[`sharing.modal.copyLink${isCopying ? '.copied' : ''}`]
    const shareViaFacebookText = messages["sharing.modal.via.facebook"]
    
    return (
      <>
        <div className="title flex large align-center gap-4">
          {modalTitle}
          <IconField
            title={messages['modal.close']}
            name="close"
            onClick={handleCloseModal}
          />
        </div>
        <div className="flex flex-col gap-20">
          <span className="text-secondary">{modalDescription}</span>
          
          <div className="generate-button-container flex flex-row gap-10">
            <span className="link-item" >{generatedLink || oldLink || `${process.env.REACT_APP_BASE_URL}/share/...`}</span>
            {isGenerated ? (
              <button disabled={isCopying} className={generateBtnClass} onClick={handleCopyLink}>
                <IconField name="linkCopy" />
                {copyButtonText}
              </button>
            ) : (
              <button disabled={loading} className={generateBtnClass} onClick={handleGenerateLink}>
                <IconField name="link" />
                {generateButtonText}
              </button>
            )}
          </div>
          {isGenerated && (
            <button
              className="btn btn-secondary w-100 truncate-text" 
              onClick={handleShareFacebook}
            >
              <IconField name="facebook" />
              {shareViaFacebookText}
            </button>
          )}
        </div>
      </>
    )
  }

  return (
    <CommonModal
      isOpen={true}
      id="sharing"
      name="sharing"
      noHeader
      noFooter
      modalClass="sharing-modal"
      element={handleRenderElement()}
    />
  )
}

export default SharingModal
