import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import IconField from '../components/iconField'
import { useSettings } from '../hooks/settingsContext'
import { useNavigate, useParams } from 'react-router-dom'
import ChatbotApi from '../api/chatbot/chatbotAPI'
import Markdown from 'react-markdown'

import './_sharing.scss'
import { toLocaleDateString } from '../common/localized'


const SharingPage = () => {
  const params = useParams()
  const { messages } = useSettings()
  const [converData, setConverData] = useState({})
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ChatbotApi.getSharingData(params.shareId,
      (err, res) => {
        let data = {}
        if (!err && res?.data) {
          data = res?.data
        }
        setConverData(data)
        setIsLoading(false)
      })
  }, [])

  const renderNotFoundElement = () => {
    return (<div className="not-found-page">
      <p>
        <span className="not-found-status">404</span>Not Found.
      </p>
    </div>)
  }

  const buildQuestion = (text, id) => (
    !_.isEmpty(text) && <li key={`message-question-${id}`} className="question">
      <div className="content">{text}</div>
    </li>
  )

  const buildAdvSection = (item) => {
    const advertisingData = _.get(item, 'advertising')
    const hasAdvertising = Boolean(advertisingData)

    if (!hasAdvertising) {
      return null
    }

    return (
      <div className="advertising">
        <div className="flex divider">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="content w-100">
          <Markdown>
            {messages['queryMoreBusModal.businessNearYou'] + advertisingData}
          </Markdown>
        </div>
      </div>
    )
  }

  const buildAnswer = (item) => {
    return (<li key={`message-answer-${item?.id}`} className="answer">
      <div className="flex">
        <IconField wrapper={{ style: { paddingTop: '4px' } }} name="chatBot" />
        <div>
          <div className="content w-100">
            <Markdown>{item?.answer}</Markdown>
          </div>
          {buildAdvSection(item)}
        </div>
      </div>
    </li>)
  }

  const renderLoading = () => {
    return (
      <div className="loadding-page" >
        <IconField name={'logoH'} />
      </div>)
  }

  const renderChatMessage = () => {
    return (<div
      className="chat-list-messages scroller"
    >
      <div className="header flex flex-col align-center gap-24" >
        <IconField name='logoH' />
        <div className="flex flex-col gap-6">
          <div className="xlarge">{_.get(converData, 'title', '')}</div>
          <div className="text-secondary small">{toLocaleDateString(_.get(converData, 'created_at', ''))}</div>
        </div>
      </div>

      <ul className="chat-messages">
        {_.map(_.get(converData, 'chat_histories', []), (data) => {
          return [
            buildQuestion(data?.question, data?.id),
            buildAnswer(data)
          ]
        })}
      </ul>
    </div>)
  }

  return (
    <div className="sharing-site-layout">
      {isLoading ? renderLoading() : (!_.isEmpty(converData) ? renderChatMessage(converData) : renderNotFoundElement())}
    </div>
  )
}

export default SharingPage
