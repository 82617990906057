import getBaseRestRequest from '../common/baseRestRequest'
import _ from 'lodash'

const restRequest = getBaseRestRequest()
const getDocumentUrl = (endpoint) =>
  process.env.REACT_APP_API_DOCUMENT + endpoint

export const DocumentApi = {
  uploadDocument: async function (data, cb) {
    await restRequest.post(
      getDocumentUrl(`?domain_id=${data?.domainId}`),
      data,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },
}

export default DocumentApi
