import getBaseRestRequest from '../common/baseRestRequest'
import _ from 'lodash'

const restRequest = getBaseRestRequest()

export const Auth = {
  login: async function (payload, cb) {
    await restRequest.post('auth/login', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  verifyOTP: async function (payload, cb) {
    await restRequest.post('auth/verify-otp', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  checkAuth: async function (cb) {
    await restRequest.get('auth/check-client-auth', {}, (err, res) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, res)
    })
  },
}

export default Auth
