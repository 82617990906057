import React, { useState, useEffect, useRef, useMemo } from 'react'
import _ from 'lodash'

import { useAuth } from '../hooks/authProvider'
import SiteNavigation from '../components/webChat/siteNavigation'
import SiteChat from '../components/webChat/siteChat'
import WelcomeBackModal from '../components/common/popup/welcomeBackModal'
import SettingsModal from '../components/common/popup/settingsModal'
import ChatActionsModal from '../components/common/popup/chatActionsModal'
import ArchivedChatsModal from '../components/common/popup/archivedChatsModal'
import ChatbotApi from '../api/chatbot/chatbotAPI'
import { CONVERSATION_STATUS } from '../common/constants'

import './_webChat.scss'

let currentUserId = null

const initCurrentChatState = {
  domainId: null,
  converId: '',
  chatMessages: {},
  is_reach_chat_limit: false,
  is_reached_tts_limit: false,
  message: '',
  status: CONVERSATION_STATUS.active,
  suggestedQuestion: [],
}

const initConversState = {
  list: {},
  needUpdate: true,
}

const initChatActionsState = {
  isOpen: false,
  id: '',
  title: '',
  isClearAll: false,
  type: '',
}

const setInitExpand = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )
  return vw >= 600
}

const WebChat = (props) => {
  const [currentChat, setCurrentChat] = useState(initCurrentChatState)
  const [convers, setConvers] = useState(initConversState)
  const [chatActions, setChatActions] = useState(initChatActionsState)
  const [isOpenArchivedChats, setIsOpenArchivedChats] = useState(false)
  const [prompts, setPrompts] = useState([])
  const [expanding, setExpanding] = useState(setInitExpand)

  const inputRef = useRef()
  const searchRef = useRef({})

  const { user, isLogin } = useAuth()

  useEffect(() => {
    if (!_.isEmpty(user) || !isLogin) {
      if (
        (currentUserId && currentUserId !== user?.id) ||
        chatActions.isClearAll
      ) {
        setCurrentChat({ ...initCurrentChatState })
        setConvers({ ...initConversState })

        if (chatActions.isClearAll) setChatActions({ ...initChatActionsState })
      }
      currentUserId = user?.id
      setCurrentChat((prev) => ({
        ...prev,
        ..._.get(user, 'user_chat_limit', {}),
        ..._.get(user, 'setting_info', {}),
      }))
    }
  }, [user, isLogin, chatActions.isClearAll])

  useEffect(() => {
    if (isLogin() && convers.needUpdate) {
      const value = _.trim(_.toLower(_.get(searchRef.current, 'value', '')))
      const currentDate = new Date()
      const calcList = (data) =>
        _.groupBy(data, (v) => {
          v.isShow = _.includes(_.toLower(v.title), value)
          const date = new Date(v?.updated_at)
          const diff = Math.floor(
            Math.abs(currentDate - date) / (1000 * 60 * 60 * 24)
          )
          if (diff > 30) {
            return date.toLocaleString('default', {
              month: 'short',
              year: 'numeric',
            })
          } else if (diff > 7) {
            return 30
          } else if (diff > 1) {
            return 7
          } else {
            return diff
          }
        })

      ChatbotApi.getConversations({}, (err, res) => {
        if (!err && res?.data) {
          setConvers((prev) => ({
            ...prev,
            list: calcList(_.get(res, 'data', [])),
            needUpdate: false,
          }))
        }
      })
    }
  }, [isLogin(), convers.needUpdate])

  const handleNewChat = (e) => {
    e && e.preventDefault()
    setPrompts([])
    setCurrentChat((prev) => ({
      ...prev,
      domainId: null,
      converId: '',
      chatMessages: {},
      status: CONVERSATION_STATUS.active,
      suggestedQuestion: [],
      sharingLink: null
    }))
    inputRef?.current?.focus()

    if (!_.isEmpty(convers.list)) {
      searchRef.current.value = ''
      setConvers((prev) => ({
        ...prev,
        list: _.transform(
          prev.list,
          (r, v, k) => (r[k] = _.map(v, (i) => ({ ...i, isShow: true })))
        ),
      }))
    }

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    if (vw < 600) setExpanding(false)
  }

  const defaultProps = useMemo(
    () => ({
      handleNewChat,
      convers,
      setConvers,
      currentChat,
      setCurrentChat,
      chatActions,
      setChatActions,
      isOpenArchivedChats,
      setIsOpenArchivedChats,
      expanding,
      setExpanding,
    }),
    [convers, currentChat, chatActions, isOpenArchivedChats, expanding]
  )

  return (
    <div className="site-layout chat-page">
      <SiteNavigation {...defaultProps} searchRef={searchRef} />
      <SiteChat
        {...defaultProps}
        inputRef={inputRef}
        prompts={prompts}
        setPrompts={setPrompts}
      />
      <WelcomeBackModal />
      <SettingsModal {...defaultProps} />
      <ChatActionsModal {...defaultProps} />
      <ArchivedChatsModal {...defaultProps} />
    </div>
  )
}

export default WebChat
