import getBaseRestRequest from '../common/baseRestRequest'
import _ from 'lodash'

const restRequest = getBaseRestRequest(true)

export const SocketApi = {
  async sendMessageChatting(payload, cb) {
    await restRequest.post(
      `chatbot/conversation/chatting`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

}

export default SocketApi
