const responseStatusCode = {
  success: 200,
  badRequest: 400,
  unauthorized: 401,
  notFound: 404,
  validationError: 422,
  serverError: 500,
  limitExceeded: 429, 
  methodNotAllowed: '405_NOT_ALLOW_METHOD'
}

const errorResponse = {
  message: 'Error response',
  undefined: 'Undefined response',
  null: 'Null response',
  empty: 'Empty response',
  validationError: 'validation Error',
}

export { responseStatusCode, errorResponse }
