import getBaseRestRequest from '../common/baseRestRequest'
import _ from 'lodash'

const restRequest = getBaseRestRequest()
const getChallengeUrl = (endpoint) =>
  process.env.REACT_APP_API_CHALLENGE + endpoint

export const ChallengeAPI = {
  requestOTPRegister: async function (data, cb) {
    await restRequest.post(
      getChallengeUrl(`/register-action`),
      data,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  emailChallenges: async function (payload, cb) {
    await restRequest.post(getChallengeUrl(''), payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  verifyEmailCode: async function (payload, cb) {
    await restRequest.post(
      getChallengeUrl('/verify'),
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },
}

export default ChallengeAPI
