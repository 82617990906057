import React, { useState } from 'react'

import CommonModal from '../modal'
import { ChallengeAPI, UserApi } from '../../../api/admin'
import { useSettings } from '../../../hooks/settingsContext'
import { useNavigate } from 'react-router-dom'
import { useFlash } from '../../../hooks/useFlash'
import { useAuth } from '../../../hooks/authProvider'
import { ACTION_OTP_TYPE, FLASH_TYPE } from '../../../common/constants'

const DeleteAccountModal = ({
  isOpen,
  setIsOpen,
  accountID,
  isThirdPartyLogin,
  handleCloseSetting,
}) => {
  const navigate = useNavigate()
  const auth = useAuth()
  const flash = useFlash()
  const { messages, setOpenSettings } = useSettings()
  const [loading, setLoading] = useState(false)

  const handleCloseModal = (isCancel = false) => {
    setIsOpen(false)
    if (isCancel) setOpenSettings(true)
    else handleCloseSetting()
  }

  const handleDeleteAccount = () => {
    if (loading) return
    setLoading(true)
    if (isThirdPartyLogin) {
      const payload = {
        is_third_party_login: isThirdPartyLogin,
        challenge_code: '',
      }
      UserApi.deleteAccount({ payload }, (err, res) => {
        if (!err) {
          flash.showFlash(FLASH_TYPE.Success, res)
          auth.setIsLogout(true)
          auth.getUser(true)
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
        handleCloseModal()
        setLoading(false)
      })
    } else {
      const payload = {
        email: accountID,
        otp_type: ACTION_OTP_TYPE.deleteAccountOtp,
      }
      ChallengeAPI.emailChallenges({ payload }, (err, res) => {
        if (!err) {
          flash.showFlash(FLASH_TYPE.Success, res)
          navigate(`/confirmation?m=${accountID}&t=delete`)
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
        handleCloseModal()
        setLoading(false)
      })
    }
  }

  const handleRenderElement = () => {
    return (
      <>
        <div className="title">{messages['deleteAccount.title']}</div>
        <div>{messages['deleteAccount.description']}</div>
        <div className="action">
          <button
            className="btn btn-secondary rad-6"
            onClick={() => handleCloseModal(true)}
          >
            {messages['deleteAccount.cancel']}
          </button>
          <button
            disabled={loading}
            className="btn btn-warning rad-6"
            onClick={handleDeleteAccount}
          >
            {messages['deleteAccount.confirm']}
          </button>
        </div>
      </>
    )
  }

  return (
    <CommonModal
      isOpen={isOpen}
      id="delete-account"
      name="delete-account"
      noHeader
      noFooter
      modalClass="delete-account-modal"
      element={handleRenderElement()}
      handleCancel={() => handleCloseModal(true)}
    />
  )
}

export default DeleteAccountModal
