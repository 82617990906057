import React, { createContext, useContext, useState } from 'react'
import _ from 'lodash'
import Flash from '../components/common/flash/Flash'
import { DEFAULT_ERROR, DEFAULT_SUCCESS } from '../common/constants'

const FlashContext = createContext()

const FlashProvider = ({ children }) => {
  const [flash, setFlash] = useState({ type: '', message: '', visible: false })

  const showFlash = (type, response = {}) => {
    let message = ''
    if (!_.isNil(response?.detail) && !_.has(response, 'error_code')) {
      message = _.get(response, 'detail', DEFAULT_SUCCESS)
    } else {
      if (_.get(response, 'data.is_list_detail')) {
        message = _.join(
          _.map(response.data?.detail, (d) => d.message || d || DEFAULT_ERROR),
          '\n'
        )
      } else {
        const dataDetail = _.get(response, 'data.detail')
        if (typeof dataDetail === 'object') {
          message = _.get(response, 'data.message') || DEFAULT_ERROR
        } else {
          message =
          _.get(response, 'data.detail') ||
          _.get(response, 'data.message') ||
          DEFAULT_ERROR
        }
      }
    }

    setFlash({ type, message, visible: true })
  }

  const hideFlash = () => {
    setFlash((prevFlash) => ({ ...prevFlash, visible: false }))
  }

  return (
    <FlashContext.Provider value={{ showFlash }}>
      {children}
      {flash.visible && (
        <Flash type={flash.type} message={flash.message} onClose={hideFlash} />
      )}
    </FlashContext.Provider>
  )
}

export default FlashProvider

export const useFlash = () => useContext(FlashContext)
