import React, { useState } from 'react'
import _ from 'lodash'

import CommonModal from '../modal'
import { useSettings } from '../../../hooks/settingsContext'

const ReachLimitModal = ({ limitModal, setLimitModal }) => {
  const { messages } = useSettings()
  const { isOpen, modalName } = limitModal
  const nameMsg = (_.isString(modalName) && !_.isEmpty(modalName)) ? modalName : 'gosipModal'

  const handleCloseModal = () => {
    setLimitModal({ isOpen: false, modalName: '', description: '' })
  }

  return (
    <CommonModal
      isOpen={isOpen}
      id="welcome-back"
      name="welcome-back"
      modalClass="comeback-modal"
      element={
        <div className="flex flex-col gap-8">
          <div className="title">{messages[`${nameMsg}.title`]}</div>
          <div className="flex flex-col gap-16">
            <div className="description text-secondary">
              {limitModal.description || messages[`${nameMsg}.description`]}
            </div>
            <button
              className="btn btn-primary w-100 truncate-text"
              onClick={handleCloseModal}
            >
              {messages[`${nameMsg}.exit`]}
            </button>
          </div>
        </div>
      }
      handleClose={handleCloseModal}
      noHeader={true}
      noFooter={true}
    />
  )
}

export default ReachLimitModal
