import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import io from 'socket.io-client'

import { useFlash } from '../hooks/useFlash'
import { FLASH_TYPE, DEFAULT_CHAT_ID } from './constants'

const useWebSocket = (user, options = {}) => {
  const ref = useRef(null)
  const flash = useFlash()
  const chat_socket_url = process.env.REACT_APP_CHAT_SOCKET_URL
  const msgRef = useRef(null)
  const socketSid = useRef(null)

  useEffect(() => {
    if (user) {

      ref.current?.disconnect()
      const socket = io(chat_socket_url, {
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 1000,
        withCredentials: true,
      })

      ref.current = socket

      socket.on('answer_responding', (data) => {
        const obj = {
          id: DEFAULT_CHAT_ID,
          text: data?.answer || '',
        }
        if (options.onMessage) options.onMessage({ obj, isGenerating: true })
      })

      socket.on('finished_answer', (data) => {
        const obj = {
          id: data?.message_id || DEFAULT_CHAT_ID,
          text: '',
          conversation_id: data.conversation_id,
        }
        if (options.onMessage) options.onMessage({ obj, isReplace: true })
        msgRef.current = null
      })

      socket.on('limit_user_reponse', (data) => {
        if (options?.onLimitUserResponse) options.onLimitUserResponse(data)
      })

      socket.on('suggested_question', (data) => {
        if (options.onGetSuggestions) {
          let id = data?.conversation_id
          const conversationId = !_.isNil(id) && !_.isEmpty(id) && `${id.slice(0, 8)}-${id.slice(8, 12)}-${id.slice(12, 16)}-${id.slice(16, 20)}-${id.slice(20)}`
          options.onGetSuggestions({
            suggestedQuestion: data?.suggested_question,
            domainId: data?.domain_id,
            conversationId,
          })
        }
      })

      socket.on('readmore_businesses', (data) => {
        if (options.onMessage)
          options.onMessage({
            domainName: data?.domain_name,
            isReadmore: data?.is_readmore_businesses,
            messageId: data?.message_id,
          })
      })

      socket.on('advertising_reponse', (data) => {
        if (options.onMessage)
          options.onMessage({
            advertising: data?.advertising_reponse,
            id: data?.message_id,
            isAds: true,
          })
      })

      socket.on('total_question', (data) => {
        if (options.onGetTotal) {
          options.onGetTotal({ totalQuestions: data?.total_question })
        }
      })

      socket.on('lock_chat', (data) => {
        if (options.onLockChat) options.onLockChat(_.get(data, 'is_lock', true))
      })

      socket.on('connect_error', (data) => {
        // flash.showFlash(FLASH_TYPE.Error, { detail: data.message }) It won't show up anymore
      })

      socket.on('error', (data) => {
        flash.showFlash(FLASH_TYPE.Error, { detail: data })
      })

      socket.on('connect', () => {
        socketSid.current = socket.id
      })

      socket.on('disconnect', () => {
        const obj = {
          id: DEFAULT_CHAT_ID,
          text: '',
        }
        if (options.onMessage) options.onMessage({ obj, isRemove: true })
        socketSid.current = null
      })

      socket.on('limit_exceeded_error', (data) => {
        if (options?.onLimitExceededError) options.onLimitExceededError(data)
      })

      return () => {
        socket.off('connect')
        socket.off('response')
        socket.off('disconnect')
        socket.off('answer_responding')
        socket.off('connect_error')
        socket.off('finished_answer')
        socket.off('send_question')
        socket.off('limit_exceeded_error')
        socket.off('error')
        socket.off('limit_user_reponse')
        socket.off('suggested_question')
        socket.off('advertising_reponse')
        socket.off('readmore_businesses')
        socket.off('total_question')
        socket.disconnect()
      }
    }
  }, [user.id])

  const sendMessage = (msg = {}) => {
    if (ref.current) {
      msgRef.current = msg
      if (ref.current.connected) {
        ref.current.emit('send_question', msg)
      } else {
        ref.current.connect()
        ref.current.emit('send_question', msg)
      }
    }
  }

  return { sendMessage, socketSid }
}

export default useWebSocket
