import React from 'react'

import IconField from '../../iconField'
import { useSettings } from '../../../hooks/settingsContext'

const NewChat = ({ expanding, handleNewChat }) => {
  const { messages } = useSettings()
  const text = expanding ? messages['navigation.newChat'] : ''

  return (
    <div className="widget">
      <button
        className="new-chat btn btn-custom truncate-text"
        onClick={handleNewChat}
      >
        <IconField name="editing" title={messages['navigation.newChat']} />
        {text}
      </button>
    </div>
  )
}

export default NewChat
