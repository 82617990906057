const GET_METHOD = 'GET'
const POST_METHOD = 'POST'
const DELETE_METHOD = 'DELETE'
const PUT_METHOD = 'PUT'
const PATCH_METHOD = 'PATCH'

const phone_regex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})-?([0-9]{4})/

const DEFAULT_LOCALE = 'vi-VN'
const appLocales = ['vi-VN']
const locales = {
  auto: 'auto',
  en: 'en-US',
  vn: 'vi-VN',
}

const DEFAULT_COUNTRY = 'us'
const countryCodes = {
  us: '+1',
}

const DEFAULT_THEME = 'dark'
const themes = {
  system: 'system',
  light: 'light',
  dark: 'dark',
}

const genders = {
  male: 'male',
  female: 'female',
  others: 'others',
}

const DEFAULT_ERROR = 'Something was wrong.'
const DEFAULT_SUCCESS = 'Success!'

const FLASH_TYPE = {
  Success: 'Success',
  Info: 'Info',
  Warning: 'Warning',
  Error: 'Error',
}

const DEFAULT_ANSWER = '...'
const DEFAULT_CHAT_ID = 'new_chat'

const ACTION_CHAT_TYPES = {
  delete: 'delete',
  archive: 'archive',
}

const SPEAKER_STATUS = {
  pausing: 'pausing',
  loading: 'loading',
  playing: 'playing',
}

const CONVERSATION_STATUS = {
  active: 'active',
  archive: 'archive',
}

const ACTION_OTP_TYPE = {
  forgotPasswordOtp: 'forgot_password_otp',
  registerOtp: 'register_otp',
  deleteAccountOtp: 'delete_account_otp',
  passwordActionOtp: 'password_action_otp',
}

const QUESTION_TYPE = {
  suggestedQuestion: 'suggested_question',
  premadeQuestion: 'premade_question',
}

export {
  GET_METHOD,
  POST_METHOD,
  PUT_METHOD,
  DELETE_METHOD,
  PATCH_METHOD,
  phone_regex,
  DEFAULT_LOCALE,
  appLocales,
  DEFAULT_COUNTRY,
  countryCodes,
  DEFAULT_THEME,
  themes,
  locales,
  genders,
  DEFAULT_ERROR,
  DEFAULT_SUCCESS,
  FLASH_TYPE,
  DEFAULT_ANSWER,
  DEFAULT_CHAT_ID,
  ACTION_CHAT_TYPES,
  SPEAKER_STATUS,
  CONVERSATION_STATUS,
  ACTION_OTP_TYPE,
  QUESTION_TYPE,
}
