import React from 'react'
import _ from 'lodash'

import { useSettings } from '../hooks/settingsContext'
import IconField from '../components/iconField'

import './_signup.scss'

const TermsOfUse = () => {
  const { messages } = useSettings()

  const paragraphClass = 'flex flex-col gap-16'
  const HeaderClass = 'flex flex-col w-100'
  const subClass = 'flex flex-col w-100 gap-6'
  const grandsubClass = 'pl-8'
  const grand2subClass = 'pl-16'
  const titleClass = 'large'
  const subTitleClass = 'small'

  return (
    <div className="site-layout scrollbar overflow-y">
      <div className="terms-container policy flex flex-col align-center">
        <div className="flex flex-col align-center gap-56 pb-40">
          <div className="flex flex-col align-center gap-20">
            <IconField name="logoM" />
            <div className="flex flex-col align-center gap-6">
              <div className='xlarge'>Terms of use</div>
            </div>
          </div>
          <div className="flex flex-col gap-40 w-100 p-20">
            <div>
              Welcome to Tellme AI, an AI-powered application offering generative content creation. By using our application, you agree to these Terms of Use. Please read them carefully before using the service.
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  1. Acceptance of Terms
                </div>
                <div className={subTitleClass}>
                  By accessing or using Tellme AI, you agree to comply with these terms and any applicable laws. If you do not agree with any part of these terms, please discontinue using the app immediately.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  2. Scope of Service
                </div>
                <div className={subTitleClass}>
                  Tellme AI provides a content creation tool powered by AI technology. We reserve the right to change, update, or discontinue any part or all of the service without prior notice.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  3. User Responsibilities
                </div>
                <div className={subTitleClass}>
                  You are solely responsible for the content you generate or share through the application. You agree not to use the app to create or distribute illegal, copyrighted, or harmful content. You will not infringe on the intellectual property rights of our application or any third parties.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  4. Intellectual Property Rights
                </div>
                <div className={subTitleClass}>
                  All intellectual property related to the technology, design, and content of Tellme AI belongs to us. You may own the content you create, but you agree to grant us the right to use this content to improve and develop our service.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  5. Privacy and Data Protection
                </div>
                <div className={subTitleClass}>
                  We are committed to protecting your personal information per Tellme AI’s Privacy Policy. By using the application, you agree that we may collect, store, and process your personal information.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  6. Limitation of Liability
                </div>
                <div className={subTitleClass}>
                  Tellme AI is not liable for any damages arising from your use or inability to use our service. We do not guarantee that our service will always be accurate, reliable, or uninterrupted.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  7. Amendments to Terms
                </div>
                <div className={subTitleClass}>
                  We reserve the right to modify or update these terms periodically. You are responsible for checking for updates. Continuing to use the service after changes are posted constitutes your acceptance of those changes.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  8. Governing Law
                </div>
                <div className={subTitleClass}>
                  These terms are governed by and interpreted according to the laws of State of California. Any disputes arising from the use of the application will be resolved under these laws.
                </div>
              </div>
            </div>
            <div className={paragraphClass}>
              <div className={HeaderClass}>
                <div className={titleClass}>
                  9. Contact Us
                </div>
                <div className={subTitleClass}>
                  If you have any questions about the Terms of Use, please contact us at info@tellme.club.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse
