import React from 'react'
import { NavLink } from 'react-router-dom'

import { useSettings } from '../../../hooks/settingsContext'

const TermsPolicy = () => {
  const { messages } = useSettings()

  return (
    <div className="flex just-cont-center w-100 gap-6">
      <NavLink to="/terms-of-use" target="_blank" className="text-primary small text-link">{messages['termsPolicy.termsOfUse']}</NavLink>
      <div className="divider-rotate"></div>
      <NavLink to="/privacy-policy" target="_blank" className="text-primary small text-link">{messages['termsPolicy.privacyPolicy']}</NavLink>
    </div>
  )
}

export default TermsPolicy
