import getBaseRestRequest from '../common/baseRestRequest'
import _ from 'lodash'

const restRequest = getBaseRestRequest()

export const ChatbotApi = {
  async startConversation(payload, cb) {
    await restRequest.post(
      'chatbot/conversation/start_conversation',
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async sendChat(chatId, payload, cb) {
    await restRequest.post(
      `chatbot/generative_answer?conversation_id=${chatId}`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async getConversations(payload, cb) {
    await restRequest.get('chatbot/conversations', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  async getConversationDetail({ id, ...payload }, cb) {
    await restRequest.get(
      `chatbot/conversation/${id}/messages`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async getArchivedChats(payload, cb) {
    await restRequest.get(
      `chatbot/conversations/archive`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async deleteConversation({ id, ...payload }, cb) {
    await restRequest.delete(
      `chatbot/conversation/${id}`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async deleteConversations(payload, cb) {
    await restRequest.delete(
      'chatbot/conversations',
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async archiveConversation({ id, ...payload }, cb) {
    await restRequest.patch(
      `chatbot/conversation/${id}/archive`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async unarchiveConversation({ id, ...payload }, cb) {
    await restRequest.patch(
      `chatbot/conversation/${id}/unarchive`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async archiveConversations(payload, cb) {
    await restRequest.patch(
      'chatbot/conversations/archive',
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async renameConversation({ id, ...payload }, cb) {
    await restRequest.patch(
      `chatbot/conversations/${id}/rename`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async translate({ id, ...payload }, cb) {
    await restRequest.get(
      `chatbot/conversation/messages/${id}/translate`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async speak({ id, is_translated, ...payload }, cb) {
    payload.headers = { 'Content-Type': 'audio/wav' }
    await restRequest.getBlob(
      `chatbot/conversation/messages/${id}/speak/${is_translated}`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async getPrompts(domainId = null, cb) {
    const getEndpoint = () => {
      let endpoint = ''
      if (domainId) {
        endpoint += `/${domainId}?is_mobile=false`
      }
      return endpoint
    }

    const endpoint = getEndpoint()

    await restRequest.get(`prompt${endpoint}`, {}, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  async updateVote(message_id, payload, cb) {
    await restRequest.patch(
      `chatbot/conversation/messages/${message_id}/vote`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async getMoreBusinesses(messageId, domainName, limit = 1, cb) {
    await restRequest.get(
      `chatbot/businesses/${messageId}/${domainName}?limit=${limit}`,
      {},
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async getDomains(domain_type = null, limit = null, cb) {
    const getEndpoint = () => {
      let endpoint = ''
      if (domain_type !== null) {
        endpoint += `?domain_type=${domain_type}`
      }
      if (limit !== null) {
        endpoint += (endpoint.includes('?') ? '&' : '?') + `limit=${limit}`
      }
      return endpoint
    }

    const endpoint = getEndpoint()

    await restRequest.get(`general/domains${endpoint}`, {}, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  async generateSharingLink(converId, cb) {
    await restRequest.post(
      `conversation/${converId}/generate_link`,
      {},
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async getSharingData(shareId, cb) {
    await restRequest.get(
      `share/${shareId}`,
      {},
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async getBusinessesNum(payload, cb) {
    await restRequest.get(
      `settings/chatbot/business_num`,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  async checkModulePermission(moduleName, cb) {
    await restRequest.get(
      `setting_module/check/${moduleName}`,
      {},
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

}

export default ChatbotApi
