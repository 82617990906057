import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { UserApi } from '../../../api/admin'
import { useSettings } from '../../../hooks/settingsContext'
import { useAuth } from '../../../hooks/authProvider'
import CommonModal from '../modal'
import IconField from '../../iconField'
import ButtonField from '../../buttonField'
import useFormBehavior from '../../../hooks/useFormBehavior'
import { useFlash } from '../../../hooks/useFlash'
import DeleteAccountModal from './deleteAccountModal'
import {
  locales,
  DEFAULT_LOCALE,
  genders,
  ACTION_CHAT_TYPES,
  FLASH_TYPE,
  DEFAULT_SUCCESS,
  countryCodes,
} from '../../../common/constants'

const config = {
  tabs: {
    general: 'general',
    location: 'location',
    profile: 'profile',
  },
  locales: { ...locales },
  genders: { ...genders },
}

const initUserInfo = {
  age: '',
  gender: '',
  language: DEFAULT_LOCALE,
  name: '',
  profession: '',
}

const formatLocation = (location = {}) => {
  const { city, state, country } = location || {}
  const separator = ', '
  const address = _.join(
    _.filter([city, state, country], (v) => v),
    separator
  )

  return address
}

const SettingsModal = ({
  setChatActions,
  setIsOpenArchivedChats,
  currentChat,
}) => {
  const { openSettings, setOpenSettings, messages } =
    useSettings()

  const fields = [
    {
      name: 'phone_number',
      inputType: 'TextInput',
      labelError: messages['settings.phoneNumber'],
    },
    {
      name: 'name',
      inputType: 'TextInput',
      validators: ['maxLength'],
      maxLength: 255,
      labelError: messages['settings.name'],
    },
    {
      name: 'age',
      inputType: 'TextInput',
      validators: ['maxLength'],
      maxLength: 50,
      labelError: messages['settings.age'],
    },
    {
      name: 'profession',
      inputType: 'TextInput',
      validators: ['maxLength'],
      maxLength: 255,
      labelError: messages['settings.profession'],
    },
    {
      name: 'gender',
      inputType: 'TextInput',
      labelError: messages['settings.gender'],
    },
    {
      name: 'language',
      inputType: 'TextInput',
      labelError: messages['settings.language'],
    },
  ]

  const {
    formData: userInfo,
    setFormData: setUserInfo,
    initFormData,
  } = useFormBehavior({ fields })

  const { user, isLogin, setUser } = useAuth()
  const isLoggedIn = isLogin()
  const navigate = useNavigate()
  const flash = useFlash()

  const [tab, setTab] = useState(config.tabs.general)

  const [isEditing, setIsEditing] = useState('')
  const inputRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    initFormData(initUserInfo)
  }, [])

  useEffect(() => {
    if (!_.isEmpty(user?.user_info)) {
      const values = _.get(user, 'user_info')
      const phoneValue = _.get(values, 'phone_number')
      const phoneNumber = _.trim(_.split(phoneValue, 'National Number:')[1])
      setUserInfo((prev) => ({ ...prev, ...values, phone_number: phoneNumber }))
    }
  }, [user])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        inputRef.current &&
        !inputRef.current.parentElement.contains(e.target)
      ) {
        // submit change info user
        const value = _.trim(inputRef.current.value)
        const initValue = inputRef.current.initValue
        submit(inputRef.current.name, value, initValue)
      }
    }

    if (isEditing) {
      const element = document.getElementById(isEditing)
      if (element) {
        inputRef.current = element
        inputRef.current.classList.remove('inactive')
        inputRef.current.disabled = false
        inputRef.current.focus()
        inputRef.current.setSelectionRange(-1, -1)
        inputRef.current.initValue = inputRef.current.value
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isEditing])

  const location = formatLocation(user?.location)
  const nameID = 'name'
  const ageID = 'age'
  const genderID = 'gender'
  const professionID = 'profession'
  const languageID = 'language'
  const questions = _.get(currentChat, 'remaining_question_today', '')
  const questionLimit = _.get(user, 'user_chat_limit.question_limit', '')
  const remainingQuestions = `${questions}/${questionLimit}`
  const tokens = _.get(currentChat, 'remaining_token_today', '')
  const tokenLimit = _.get(user, 'user_chat_limit.token_limit', '')
  const remainingTokens = `${tokens}/${tokenLimit}`
  const accountID = _.get(user, 'email', '')
  const isThirdPartyLogin = _.get(user, 'is_third_party_login')
  let phone = _.get(user, 'phone_number', '')
  if (phone) {
    phone = `${phone.substring(0, 2)} (${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 12)}`
  }

  const submit = (name, value, initValue = '') => {
    let phoneNumber = name === 'phone_number' ? value : userInfo['phone_number']
    const payload = { [name]: value }
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/\D+/g, '')
      payload['phone_number'] = countryCodes['us'] + phoneNumber
    }

    if (value !== initValue) {
      UserApi.updateUser({ payload }, (err, res) => {
        if (!err && res && res?.data) {
          setUserInfo((prev) => ({ ...prev, [name]: value }))
          if (!_.includes(['language'], name))
            flash.showFlash(FLASH_TYPE.Success, res) // Not show flash for language
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
          if (isEditing) {
            setUserInfo((prev) => ({ ...prev, [name]: initValue }))
          }
        }
      })
    }

    if (isEditing) {
      setIsEditing('')
      inputRef.current.classList.add('inactive')
      inputRef.current.disabled = true
      inputRef.current = null
    }
  }

  const handleClose = () => {
    setTab(config.tabs.general)
    setOpenSettings(false)
  }

  const openDeleteAccount = () => {
    setIsOpen(true)
    setOpenSettings(false)
  }

  const handleNavigate = (value) => {
    setOpenSettings(false)
    navigate(value)
  }

  const handleEdit = (e, value) => {
    e.preventDefault()
    setIsEditing(value)
  }

  const handleChange = (e) => {
    let { name, value, type } = e.target

    if (e.keyCode === 13 || type === 'radio') {
      value = _.trim(value)
      const initValue = inputRef.current?.initValue
      submit(name, value, initValue)
    } else {
      if (name === 'phone_number') {
        let value = e.target.value
          .replace(countryCodes['us'], '')
          .replace(/\D+/g, '')
        const old = userInfo[name]?.replace(/\D+/g, '')

        let result = ''
        let isChanged = false

        if (value.length === 11 && value.substring(0, 1) === '1') {
          value = value.slice(1)
        }

        if (!_.isEqual(value, old) && value.length <= 10) {
          if (value.substring(0, 3)) result += `(${value.substring(0, 3)})`
          if (value.substring(3, 6)) result += ` ${value.substring(3, 6)}`
          if (value.substring(6, 10)) result += `-${value.substring(6, 10)}`
          isChanged = true
        }

        if (e.target.selectionStart > 14 && !isChanged) return

        if (isChanged) {
          setUserInfo((prevUserInfo) => ({ ...prevUserInfo, [name]: result }))
        }
      } else {
        setUserInfo((prev) => ({ ...prev, [name]: value }))
      }
    }
  }

  const detectLocation = () => {
    const getIpAddress = async (cb) => {
      const { ipAddress } = await fetch(
        'https://api.db-ip.com/v2/free/self'
      ).then((response) => response.json(), 'jsonp')
      UserApi.updateLocation({ ipAddress }, (err, response) => {
        if (!_.isEmpty(response?.data)) {
          return cb(response?.data || {})
        } else {
          return cb({})
        }
      })
    }

    getIpAddress((address) => {
      setUser((prev) => ({ ...prev, location: address }))
    })
  }

  const renderRadio = ({ label, ...props }) => {
    return (
      <div className="gap-6">
        <input type="radio" {...props} />
        <label htmlFor={props.id}>{label}</label>
      </div>
    )
  }

  const renderText = (name, attrs = {}) => {
    return (
      <>
        <input
          id={name}
          name={name}
          value={userInfo[name] || ''}
          disabled={true}
          className="input-text truncate-text inactive"
          onKeyUp={handleChange}
          onChange={handleChange}
          title={userInfo[name]}
          {...attrs}
        />
        <IconField
          name="editInfo"
          wrapper={{ className: `c-pointer` }}
          onMouseUp={(e) => handleEdit(e, name)}
        />
      </>
    )
  }

  const formatPhoneNumber = (value) => {
    if (!value) return ''
    const cleanValue = value.replace(/\D+/g, '') // Remove non-digit characters
    let formatted = ''
    if (cleanValue.length > 3) {
      formatted += `(${cleanValue.substring(0, 3)})`
    } else {
      formatted += cleanValue.substring(0, 3)
    }
    if (cleanValue.substring(3, 6))
      formatted += ` ${cleanValue.substring(3, 6)}`
    if (cleanValue.substring(6, 10))
      formatted += `-${cleanValue.substring(6, 10)}`
    return formatted
  }

  const renderPhoneNumber = (name) => {
    return (
      <>
        <IconField
          name="flagUSA"
          className={isEditing === name || userInfo[name] ? '' : 'hidden'}
        />
        <input
          id={name}
          name={name}
          value={formatPhoneNumber(userInfo[name])}
          disabled={true}
          className="input-text phone-number inactive"
          onKeyUp={handleChange}
          onChange={handleChange}
          title={userInfo[name]}
        />
        <IconField
          name="editInfo"
          wrapper={{ className: `c-pointer` }}
          onMouseUp={(e) => handleEdit(e, name)}
        />
      </>
    )
  }

  const renderSettings = () => {
    const tabs = [
      {
        key: config.tabs.general,
        title: messages['settings.tabGeneral'],
        name: 'settingTab',
        handleClick: () => setTab(config.tabs.general),
      },
      {
        key: config.tabs.location,
        title: messages['settings.tabLocation'],
        name: 'location',
        handleClick: () => setTab(config.tabs.location),
      },
      {
        key: config.tabs.profile,
        title: messages['settings.tabProfile'],
        name: 'profile',
        handleClick: () => setTab(config.tabs.profile),
      },
    ]

    const openArchivedChats = (e) => {
      setIsOpenArchivedChats(true)
      setOpenSettings(false)
    }

    const openChatAction = (e, type) => {
      setChatActions((prev) => ({ ...prev, isOpen: true, type: type }))
      setOpenSettings(false)
    }

    const content = {
      [config.tabs.general]: (
        <>
          <div className="gap-8">
            <div className="item">{messages['settings.language']}</div>
            <div className="gap-24">
              {renderRadio({
                id: `language-${config.locales.vn}`,
                name: languageID,
                label: messages['settings.language.vietnam'],
                value: config.locales.vn,
                checked: !_.includes([config.locales.vn, config.locales.en], userInfo[languageID]) || userInfo[languageID] === config.locales.vn,
                onChange: (e) => handleChange(e),
              })}
              {renderRadio({
                id: `language-${config.locales.en}`,
                name: languageID,
                label: messages['settings.language.english'],
                value: config.locales.en,
                checked: userInfo[languageID] === config.locales.en,
                onChange: (e) => handleChange(e)
              })}
            </div>
          </div>
          {isLoggedIn && (
            <>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.archivedChats']}</div>
                <button className="btn btn-secondary p-button" onClick={(e) => openArchivedChats(e)}>
                  {messages['settings.archiveManage']}
                </button>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">
                  {messages['settings.archiveAllChats']}
                </div>
                <button className="btn btn-secondary p-button" onClick={(e) => openChatAction(e, ACTION_CHAT_TYPES.archive)}>
                  {messages['settings.archiveAll']}
                </button>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.deleteChats']}</div>
                <button className="btn btn-secondary warning p-button" onClick={(e) => openChatAction(e, ACTION_CHAT_TYPES.delete)}>
                  {messages['settings.deleteAll']}
                </button>
              </div>
            </>
          )}
        </>
      ),
      [config.tabs.location]: (
        <>
          <div className="gap-8">
            <div className="item">{messages['settings.yourLocation']}</div>
            {!location
              ? <button className="btn btn-secondary p-button" onClick={() => detectLocation()}>
                {messages['settings.allow']}
              </button>
              : <div>{location}</div>}
          </div>
        </>
      ),
      [config.tabs.profile]: (
        <>
          <div className="gap-8">
            <div className="item">{messages['settings.questionLimit']}</div>
            <div>{remainingQuestions}</div>
          </div>
          <div className="divider"></div>
          <div className="gap-8">
            <div className="item">{messages['settings.tokenLimit']}</div>
            <div>{remainingTokens}</div>
          </div>
          {isLoggedIn ? (
            <>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.accountID']}</div>
                <span className="email truncate-text" title={accountID}>
                  {accountID}
                </span>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.phoneNumber']}</div>
                <div className="phone-number gap-8">
                  {renderPhoneNumber('phone_number')}
                </div>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.name']}</div>
                <div className="align-center gap-8">
                  {renderText(nameID, { maxLength: 255 })}
                </div>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.age']}</div>
                <div className="align-center gap-8">
                  {renderText(ageID, { maxLength: 3 })}
                </div>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.gender']}</div>
                <div className="gap-24">
                  {renderRadio({
                    id: `gender-male`,
                    name: genderID,
                    label: messages['settings.gender.male'],
                    value: config.genders.male,
                    checked: userInfo[genderID] === config.genders.male,
                    onChange: (e) => handleChange(e),
                  })}
                  {renderRadio({
                    id: `gender-female`,
                    name: genderID,
                    label: messages['settings.gender.female'],
                    value: config.genders.female,
                    checked: userInfo[genderID] === config.genders.female,
                    onChange: (e) => handleChange(e),
                  })}
                  {renderRadio({
                    id: `gender-others`,
                    name: genderID,
                    label: messages['settings.gender.others'],
                    value: config.genders.others,
                    checked: userInfo[genderID] === config.genders.others,
                    onChange: (e) => handleChange(e),
                  })}
                </div>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.profession']}</div>
                <div className="align-center gap-8">
                  {renderText(professionID, { maxLength: 255 })}
                </div>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">{messages['settings.deleteAccount']}</div>
                <button className="btn btn-secondary warning p-button" onClick={() => openDeleteAccount()}>
                  {messages['settings.delete']}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">
                  {messages['settings.accessYourAccount']}
                </div>
                <button className="btn btn-secondary p-button" onClick={() => handleNavigate('/login')}>
                  {messages['settings.logIn']}
                </button>
              </div>
              <div className="divider"></div>
              <div className="gap-8">
                <div className="item">
                  {messages['settings.createAnAccount']}
                </div>
                <button className="btn btn-secondary p-button" onClick={() => handleNavigate('/signup')}>
                  {messages['settings.signUp']}
                </button>
              </div>
            </>
          )}
        </>
      ),
    }

    return (
      <>
        <div className="tab flex flex-col gap-8">
          {_.map(tabs, (item) => (
            <div
              key={item.key}
              className={`item flex align-center gap-6 p-button c-pointer${item.key === tab ? ' active' : ''}`}
              onClick={item.handleClick}
            >
              <IconField name={item.name} />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
        <div className="content">{content[tab]}</div>
      </>
    )
  }

  return (
    <>
      <CommonModal
        isOpen={openSettings}
        id="settings"
        name="settings"
        title={messages['settings.title']}
        modalClass="settings-modal"
        handleClose={handleClose}
        element={openSettings ? renderSettings() : <></>}
        noFooter={true}
      />
      <DeleteAccountModal
        accountID={accountID}
        handleCloseSetting={handleClose}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isThirdPartyLogin={isThirdPartyLogin}
      />
    </>
  )
}

export default SettingsModal
