import React, { useEffect } from 'react'
import _ from 'lodash'

import IconField from '../../iconField'
import { useSettings } from '../../../hooks/settingsContext'

import './_flash.scss'

const Flash = ({ type, message, onClose }) => {
  const { messages } = useSettings()

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, 3000)

    return () => clearTimeout(timer)
  }, [onClose])

  const name = `icon${type}`
  return (
    <div className="overlay">
      <div
        className={`custom-flash ${_.toLower(type)} flex align-center gap-16`}
      >
        <div className="icon-title">
          <IconField title="Icon message" name={name} />
        </div>
        <p className="message"> {message} </p>
        <div className="icon-close c-pointer">
          <IconField
            title={messages['modal.close']}
            name="close"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  )
}

export default Flash
