import React from 'react'

import { useSettings } from '../../../hooks/settingsContext'
import IconField from '../../iconField'
import ButtonField from '../../buttonField'

const Banner = ({ onClick, expanding }) => {
  const { messages } = useSettings()

  return (
    <div className="banner flex align-center">
      {expanding && <IconField name="logo" />}
      <ButtonField
        element={<IconField name="menu" />}
        onClick={onClick}
        className={`btn btn-icon p-0${expanding ? ' reverse' : ''}`}
        title={
          expanding
            ? messages['navigation.closeSidebar']
            : messages['navigation.openSidebar']
        }
      />
    </div>
  )
}

export default Banner
