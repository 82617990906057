import React from 'react'
import _ from 'lodash'

import IconField from '../../iconField'
import { useSettings } from '../../../hooks/settingsContext'
import ChatbotApi from '../../../api/chatbot/chatbotAPI'

const Welcome = ({ domainId, domains, setCurrentChat, setPrompts }) => {
  const { messages } = useSettings()

  const handleOnclickChip = (domainId) => {
    setCurrentChat((prev) => ({ ...prev, domainId }))
    ChatbotApi.getPrompts(domainId, (err, res) => {
      if (!err && res?.data) {
        setPrompts(res?.data)
      }
    })
  }

  const iconNames = [
    'dentist',
    'estate',
    'finance',
    'immigration',
    'insurance',
    'loan',
    'medical',
    'protection',
  ]

  return (
    <div className="welcome">
      <div className="logo">
        <IconField name="logoH" />
      </div>
      <div className={`greeting text-primary ani-right-drift`}>
        {domainId ? messages['welcome.choosePrompt'] : messages['welcome.greeting']}
      </div>
      {!domainId && (
        <div className="domain-grid flex just-cont-center gap-8 p-20">
          {domains.map((d, index) => (
            <div
              key={index}
              className={`domain-chip flex gap-8 align-center c-pointer ani-right-drift-large ani-${index}`}
              onMouseUp={() => handleOnclickChip(d?.id)}
            >
              <IconField
                name={_.find(iconNames, (i) => _.includes(d?.icon, i))}
              />
              {d?.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Welcome
