import React, { useState, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { useAuth } from '../hooks/authProvider'
import { useSettings } from '../hooks/settingsContext'
import { UserApi, ChallengeAPI } from '../api/admin'
import IconField from '../components/iconField'
import InputField from '../components/common/inputField'
import useFormBehavior from '../hooks/useFormBehavior'
import { useFlash } from '../hooks/useFlash'
import { FLASH_TYPE } from '../common/constants'
import TermsPolicy from '../components/webChat/components/termsPolicy'
import './_signup.scss'

const Signup = ({ scope = 'signup' }) => {
  const flash = useFlash()
  const { messages } = useSettings()
  const [loading, setLoading] = useState(false)

  const fields = (type = scope) => [
    {
      name: 'email',
      inputType: 'TextInput',
      labelError: messages['signup.email.placeholder'],
      validators: ['required', 'maxLength', 'email'],
      maxLength: 320,
    },
    {
      name: 'password',
      type: 'password',
      inputType: 'TextInput',
      labelError: messages['signup.password.placeholder'],
      validators: type === 'signup' ? ['required', 'password', 'maxLength'] : ['required'],
      maxLength: 255,
    },
  ]

  const {
    isFormValid,
    formData: user,
    setFormData: setUser,
    validateField,
    errors,
    handleBlur,
    handleChange,
    validateForm,
  } = useFormBehavior({ fields: fields() })

  const pwRef = useRef()
  const auth = useAuth()

  const navigate = useNavigate()

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)
    const otpPayload = {
      email: user.email,
    }

    const contSignup = () => {
      ChallengeAPI.requestOTPRegister({ payload: otpPayload }, (err, res) => {
        if (!err && res?.data) {
          navigate(`/confirmation?m=${user.email}&t=signup`, {
            state: { password: user.password },
          })
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
        setLoading(false)
      })
    }

    const payload = {
      password: user.password,
      user_name: user.email,
    }

    const contLogin = () => {
      auth.loginAction(payload, (err) => {
        flash.showFlash(FLASH_TYPE.Error, err)
        setLoading(false)
      })
    }

    if (scope === 'signup') contSignup()
    else contLogin()
  }

  const continueGoogle = () => {
    UserApi.continueGoogle({}, (err, response) => {
      if (response?.url) {
        window.location.href = response?.url
      }
    })
  }

  const continueApple = () => {
    UserApi.continueApple({}, (err, response) => {
      if (response?.url) {
        window.location.href = response?.url
      }
    })
  }

  const renderErrors = (error) => {
    const isValid = _.isEmpty(error)
    if (isValid) return null

    return (
      <div className="error-message">
        <p>{error.message ?? error}</p>
      </div>
    )
  }

  const handleRemoveUsername = () => {
    setUser((prevUser) => ({ ...prevUser, email: '' }))
    validateField(fields()[0], '')
  }

  const renderEmailInput = () => (
    <>
      <div
        className={`email-input input-container flex align-center ${errors['email'] && 'error'}`}
      >
        <input
          name="email"
          value={user.email || ''}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={messages[`${scope}.email.placeholder`]}
        />
        <IconField
          name="close"
          wrapper={true}
          onClick={handleRemoveUsername}
          className={!!user.email ? 'show' : 'hidden'}
        />
      </div>
      {errors['email'] && renderErrors([errors['email']])}
    </>
  )

  const handleChangeScope = () => {
    const rule = scope === 'signup' ? 'login' : 'signup'
    validateField(fields(rule)[0], user.email)
    validateField(fields(rule)[1], user.password)
  }

  const renderPwInput = () => {
    const togglePw = (isShow = false) => {
      if (!pwRef.current) return

      pwRef.current?.setAttribute('type', isShow ? 'text' : 'password')
    }

    return (
      <>
        <div
          className={`pw-input input-container flex align-center ${errors['password'] && 'error'}`}
        >
          <input
            name="password"
            ref={pwRef}
            type="password"
            value={user.password || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={messages[`${scope}.password.placeholder`]}
          />
          <IconField
            onMouseDown={() => togglePw(true)}
            onMouseUp={() => togglePw(false)}
            name="eyeLine"
            wrapper={true}
            className={!!user.password ? 'show' : 'hidden'}
          />
        </div>
        {errors['password'] && renderErrors([errors['password']])}
      </>
    )
  }

  const renderForgotPassword = () => {
    return scope === 'login' && (
      <div className='forgot small'>
        <NavLink className='text-link' to='/reset-password/m'>{messages[`${scope}.forgotPW`]}</NavLink>
      </div>
    )
  }

  return (
    <div className="site-layout">
      <div className={`${scope}-container flex flex-col`}>
        <div className="flex flex-col align-center gap-20">
          <IconField name="logoM" />
          <div className="main flex flex-col align-center">
            <div className="top flex flex-col align-center">{messages[`${scope}.header`]}</div>
            <div className="middle flex flex-col align-center">
              {renderEmailInput()}
              {renderPwInput()}
              {renderForgotPassword()}
              <button
                disabled={
                  !_.isEmpty(errors) ||
                  Object.keys(user)?.length === 0 ||
                  _.some(Object.values(user).map((v) => _.isEmpty(v))) ||
                  loading
                }
                className="btn btn-primary w-100"
                onClick={submit}
              >
                {messages[`${scope}.submit`]}
              </button>
              <div className="flex flex-col align-center">
                <p className="text-secondary small">
                  {messages[`${scope}.alrHaveAcc`]}
                  <NavLink
                    className="text-link"
                    to={scope === 'signup' ? '/login' : '/signup'}
                    onClick={handleChangeScope}
                  >
                    {messages[`${scope}.navigate`]}
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="divider flex align-center">
              <div />
              <div className="flex just-cont-center text-secondary small">{messages[`${scope}.or`]}</div>
              <div />
            </div>
            <div className="bottom flex flex-col align-center">
              <button
                className="btn btn-secondary w-100"
                onClick={continueGoogle}
              >
                <IconField name="logoGoogle" />
                <span>{messages[`${scope}.contGoogle`]}</span>
              </button>
              <button className="btn btn-secondary w-100" onClick={continueApple}>
                <IconField name="logoApple" />
                <span>{messages[`${scope}.contApple`]}</span>
              </button>
            </div>
            <NavLink
              className="text-link"
              to={'/'}
              onClick={handleChangeScope}
            >
              {messages['welcomeModal.linkStayLogout']}
            </NavLink>
          </div>
        </div>
        <TermsPolicy />
      </div>
    </div>
  )
}

export default Signup
