import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'

import { useAuth } from '../../../hooks/authProvider'
import ChatbotApi from '../../../api/chatbot/chatbotAPI'
import IconField from '../../iconField'
import ButtonField from '../../buttonField'
import { useSettings } from '../../../hooks/settingsContext'
import {
  DEFAULT_ANSWER,
  DEFAULT_CHAT_ID,
  FLASH_TYPE,
  SPEAKER_STATUS,
  CONVERSATION_STATUS,
  QUESTION_TYPE,
} from '../../../common/constants'
import { useFlash } from '../../../hooks/useFlash'
import { settingModuleMapping } from '../../../common/enum'
import { responseStatusCode } from '../../../common/responseStatus'

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition

let localStream = null

const ChatBox = ({
  updateChatMessages,
  currentChat,
  setCurrentChat,
  inputRef,
  setConvers,
  loading,
  setLoading,
  sendMessage,
  prompt,
  handleLimitRequest
}) => {
  const flash = useFlash()
  const [value, setValue] = useState('')
  const {
    domainId,
    converId,
    is_reach_chat_limit,
    status,
    disclaimer,
    suggestedQuestion,
  } = currentChat

  const { user, isLogin, setUser, getSetting } = useAuth()
  const showMicro = _.get(user, 'setting_module.using_speech_to_text')
  const isLoggedIn = isLogin()

  const { messages } = useSettings()
  const isHiddenStt = _.get(user, "user_chat_limit.message", "") !== ""
  const speechRef = useRef()
  const [speeching, setSpeeching] = useState(false)

  useEffect(() => {
    if (_.isEmpty(user) || !isLoggedIn ) {
      if (speechRef.current) {
        setSpeeching(false)
        speechRef.current = null
      }
      return
    }
    if (isLoggedIn && !speechRef.current && SpeechRecognition) {
      speechRef.current = new SpeechRecognition()
      speechRef.current.continuous = true
      speechRef.current.lang = 'vi-VN'
      speechRef.current.interimResults = true
      speechRef.current.maxAlternatives = 1
  
      speechRef.current.onresult = (event) => {
        let transcript = ''
        for (let i = 0; i < event.results.length; i++) {
          transcript += event.results[i][0]?.transcript || ''
        }
        setValue(transcript)
      }
    }

    return () => {
      if (speechRef.current) {
        speechRef.current?.stop()
        speechRef.current = null
      }
    }
  }, [user, isLoggedIn])

  
  useEffect(() => {
    const maxRows = 3
    const { clientWidth } = inputRef.current
    let lines = value.split('\n')
    let count = lines.length
    if (count < maxRows) {
      _.forEach(lines, (v) => {
        if (v.length) {
          const allWords = v.split(' ') || []
          let currentLine = ''
          let span = document.createElement('span')
          span.style['position'] = 'absolute'
          span.style['visibility'] = 'hidden'
          span.style['white-space'] = 'nowrap'
          span.style['padding'] = '4px 8px 4px 16px'
          document.body.appendChild(span)
          for (let i = 0; i < allWords.length; i++) {
            const newLine = currentLine + allWords[i] + ' '
            span.innerText = newLine
            if (span.clientWidth > clientWidth) {
              count++
              currentLine = allWords[i] + ' '
            } else {
              currentLine = newLine
            }
          }
          span.remove()
        }
      })
    }

    inputRef.current.rows = Math.min(maxRows, count)
  }, [value])

  useEffect(() => {
    if (prompt) {
      handleSendMessage(prompt, sendChatMessage, false, true)
    }
  }, [prompt])

  const clearSpeeching = () => {
    speechRef.current && speechRef.current.stop()
    if (speeching) setSpeeching(false)
  }

  const speechHandler = () => {
    const speak = () => {
      if (speeching) {
        clearSpeeching()
        localStream?.getTracks().forEach(track => track.stop())
      } else {
        speechRef.current?.start()
        setSpeeching(true)
        setValue('')
      }
    }

    const getLocalStream = () => {
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          localStream = stream
          speak()
        })
        .catch((err) => {
          localStream = null
          flash.showFlash(FLASH_TYPE.Error, {
            detail: messages['error.microphoneNotAllowed'],
          })
        })
    }

    if (localStream)
      speak()
    else
      getLocalStream()
  }

  const handleSpeech = () => {
    ChatbotApi.checkModulePermission(settingModuleMapping.speechToText, (err, res) => {
      if (!err && res) {
        speechHandler()
      } else {
        handleLimitRequest(err)
      }
    })
  }

  const handleChange = (e) => {
    const value = _.trim(e.target.value)
    if (speeching) clearSpeeching()
    if (value && e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage(value, sendChatMessage)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSendMessage(value, sendChatMessage)
  }

  const handleSendMessage = (
    msg,
    cb,
    isSuggested = false,
    isPremadeQuestion = false,
  ) => {
    if (loading) return
    if (speeching) clearSpeeching()
    setLoading(true)
    if (!converId) {
      startConversation((conversationId) => {
        if (conversationId) {
          cb(
            msg,
            conversationId,
            isSuggested,
            isPremadeQuestion,
          )
        }
      })
    } else {
      cb(msg, '', isSuggested, isPremadeQuestion,)
    }
  }

  const sendChatMessage = (
    questionText,
    conversationId,
    isSuggested = false,
    isPremadeQuestion = false,
  ) => {
    let obj = {
      id: DEFAULT_CHAT_ID,
      question: { type: 'question', text: questionText },
      answer: { type: 'answer', text: DEFAULT_ANSWER },
      answer_trans: { type: 'answer', text: DEFAULT_ANSWER },
      origin: true,
      speaker: SPEAKER_STATUS.pausing,
      isSuggested: isSuggested,
      questionType: isSuggested ? QUESTION_TYPE.suggestedQuestion : (!!domainId && isPremadeQuestion ? QUESTION_TYPE.premadeQuestion : null),
      isLiked: false,
      domain_id: isPremadeQuestion ? domainId : null,
    }
    setValue('')
    updateChatMessages({ obj })
    sendMessage({
      message: questionText,
      conversation_id: converId || conversationId,
      is_suggested_question: isSuggested,
      domain_id: isPremadeQuestion ? domainId : null,
    })
  }

  const startConversation = (cb) => {
    if (!converId && !_.isEmpty(user)) {
      const payload = {
        collection_name: '',
      }
      ChatbotApi.startConversation({ payload }, (err, res) => {
        if (res?.data) {
          setCurrentChat((prev) => ({
            ...prev,
            converId: res?.data?.conversation_id,
            status: CONVERSATION_STATUS.active,
          }))

          if (_.isFunction(cb)) cb(res?.data?.conversation_id)
        }
      })
    }
  }

  const handleClickChip = (e, suggestion) => {
    e.preventDefault()
    // remove prev clicked SQ
    setCurrentChat((prev) => ({
      ...prev,
      suggestedQuestion: _.filter(_.get(prev, 'suggestedQuestion', []), (question) => question !== suggestion)
     })
    )
    // send another question
    handleSendMessage(
      suggestion,
      sendChatMessage,
      true,
    )
  }

  const renderSuggestedQuestions = () => {
    if (is_reach_chat_limit || _.isEmpty(suggestedQuestion)) return <></>
    return (
      <div className="suggested-field gray-scroll flex gap-8">
        {_.map(suggestedQuestion, (suggestion, index) => (
          <button
            key={index}
            disabled={loading}
            className="suggested-chip flex flex-col gap-8"
            onClick={(e) => handleClickChip(e, suggestion)}
          >
            <div className="chip-content">
              {suggestion}
            </div>
          </button>
        ))}
      </div>
    )
  }

  const handleUnarchive = (e) => {
    e.preventDefault()
    const payload = {
      id: converId,
    }
    ChatbotApi.unarchiveConversation(payload, (err, res) => {
      if (!err && res) {
        setConvers((prev) => ({ ...prev, needUpdate: true }))
        setCurrentChat((prev) => ({
          ...prev,
          status: CONVERSATION_STATUS.active,
        }))
      }
    })
  }

  const renderArchivedChat = () => {
    if (status !== CONVERSATION_STATUS.archive) return <></>
    return (
      <>
        <div>{messages['chatBox.archivedChatText']}</div>
        <div>
          <ButtonField
            element={
              <>
                <IconField name="unarchive" className="icon-primary" />
                {messages['archivedChat.unarchive']}
              </>
            }
            className={`btn btn-primary p-16`}
            onMouseUp={handleUnarchive}
          />
        </div>
      </>
    )
  }

  const renderDisclaimer = () => {
    if (!disclaimer || status === CONVERSATION_STATUS.archive) return <></>
    return <span className="text-subtitle">{disclaimer}</span>
  }

  const renderInput = () => {
    const inputDisabled = is_reach_chat_limit
    const buttonDisabled = !_.trim(value) || loading || is_reach_chat_limit
    const hidden = status === CONVERSATION_STATUS.archive ? ' hidden' : ''
    return (
      <div className={`chat-field flex align-center${hidden}`}>
        <textarea
          ref={inputRef}
          value={value}
          type="text"
          className="text-primary scrollbar"
          placeholder={
            speeching
              ? messages['chatBox.listening']
              : messages['chatBox.input.placeholder']
          }
          onKeyDown={handleChange}
          onFocus={clearSpeeching}
          onChange={(e) => setValue(e.target.value)}
          disabled={inputDisabled}
        />
        <div className="flex gap-4 align-center">
          {showMicro && speechRef.current && (
            <button
              className={`btn btn-icon relative${speeching ? ' active' : ''} ${isHiddenStt ? 'disabled-icon' : ''}`}
              onClick={handleSpeech}
            >
              <IconField name="micro" title={messages['chatBox.micro']} />
            </button>
          )}
          <button
            className="btn btn-icon"
            onClick={handleSubmit}
            disabled={buttonDisabled}
          >
            <IconField
              name={loading ? 'loading' : 'send'}
              title={messages['chatBox.send']}
            />
          </button>
        </div>
      </div>
    )
  }

  const className =
    status === CONVERSATION_STATUS.archive ? ' gap-16 align-center' : ' gap-4'

  return (
    <>
      {renderSuggestedQuestions()}
      <div className={`chat-box flex flex-col${className}`}>
        {renderArchivedChat()}
        {renderInput()}
        {renderDisclaimer()}
      </div>
    </>
  )
}

export default ChatBox
