import React, { useState } from 'react'

import NewChat from './components/newChat'
import LoginWidget from './components/loginWidget'
import Banner from './components/banner'
import History from './components/history'
import { useAuth } from '../../hooks/authProvider'

const SiteNavigation = ({
  handleNewChat,
  setExpanding,
  expanding,
  ...props
}) => {
  const auth = useAuth()

  const closeSitebar = (e) => {
    e.preventDefault()
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    if (vw < 600 && [...e.target.classList].includes('site-navigation'))
      setExpanding(false)
  }

  return (
    <div className={`site-navigation`} onMouseUp={closeSitebar}>
      <div
        className={`site-container flex flex-col ${expanding ? 'expanding' : ''}`}
      >
        <div className="flex flex-col gap-24">
          <Banner
            onClick={() => setExpanding((prev) => !prev)}
            expanding={expanding}
          />
          <NewChat expanding={expanding} handleNewChat={handleNewChat} />
        </div>
        {expanding && <History {...props} />}
        <LoginWidget
          isShow={!auth?.isLogin() && expanding}
          isShowGuidance={true}
        />
      </div>
    </div>
  )
}

export default SiteNavigation
