import { createContext, useContext, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'

import {
  DEFAULT_LOCALE,
  appLocales,
} from '../common/constants'

const languageFiles = []
_.forEach(appLocales, (value) => {
  languageFiles.push(import(`./../assets/languages/${value}.json`))
})

const translationMessages = {}

const SettingsContext = createContext(null)

export const SettingsProvider = ({ children }) => {
  const [openSettings, setOpenSettings] = useState(false)
  const [loading, setLoading] = useState(true)
    const messages = {
    ...translationMessages[DEFAULT_LOCALE],
  }

  useEffect(() => {
    Promise.all(languageFiles).then((v) => {
      _.forEach(appLocales, (value, index) => {
        translationMessages[value] = v[index]
      })
      setLoading(false)
    })
  }, [])

  const value = useMemo(
    () => ({
      openSettings,
      setOpenSettings,
      messages,
    }),
    [openSettings, messages]
  )

  if (loading) return <></>

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => useContext(SettingsContext)
