import React, { useRef } from 'react'
import _ from 'lodash'

const TextInput = (props) => {
  const {
    name,
    value,
    label,
    placeholder,
    error,
    onBlur,
    onChange,
    type,
    isReadOnly,
    className,
    iconElement,
    validators,
    onKeyDown,
  } = props
  const inputRef = useRef()
  const isValid = _.isEmpty(error)

  const handleInputChange = (e, index) => {
    if (_.isFunction(onChange)) {
      onChange(e, index)
    }
  }

  const handleInputBlur = (e) => {
    if (_.isFunction(onBlur)) {
      onBlur(e)
    }
  }

  const handleKeyDown = (e) => {
    if (_.isFunction(onKeyDown)) {
      onKeyDown(e)
    }
  }

  const renderErrors = () => {
    const isValid =
      _.isEmpty(error) || _.includes(validators, 'confirmation_code')
    if (isValid) return null

    return (
      <div className="error-message">
        <p>{error.message ?? error}</p>
      </div>
    )
  }

  return (
    <>
      <div ref={inputRef} className={`${className} ${isValid ? '' : 'error'}`}>
        <label htmlFor={name} className="title">
          {label}
        </label>

        <input
          autoComplete="off"
          disabled={isReadOnly}
          id={name}
          type={type || 'text'}
          value={value || ''}
          name={name}
          placeholder={placeholder}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />

        {iconElement}
      </div>
      {renderErrors()}
    </>
  )
}

export default TextInput
