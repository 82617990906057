import getBaseRestRequest from '../common/baseRestRequest'
import _ from 'lodash'

const restRequest = getBaseRestRequest()

export const UserApi = {
  getUser: async function (cb) {
    await restRequest.get('user/me', {}, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  updateUser: async function (payload, cb) {
    await restRequest.patch(`user/me/update-info`, payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  logout: async function (payload, cb) {
    await restRequest.post('auth/logout', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  register: async function (payload, cb) {
    await restRequest.post('user/register', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  // Account Management
  getListAcountAdmin: async function (payload, cb) {
    await restRequest.get('admin/admins', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  createAccountAdmin: async function (payload, cb) {
    await restRequest.post('admin/admins', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  updateAccountAdmin: async function (userId, payload, cb) {
    await restRequest.patch(
      'admin/admins/' + userId,
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  disableAccountAdmin: async function (userId, payload, cb) {
    await restRequest.patch(
      'admin/admins/' + userId + '/change-active',
      payload,
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  continueGoogle: async function (payload, cb) {
    await restRequest.get('user/login/google', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  continueApple: async function (payload, cb) {
    await restRequest.get('user/login/apple', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  getLocation: async function ({ latitude, longitude }, cb) {
    await restRequest.get(
      `general/detect-location/${latitude}/${longitude}`,
      {},
      (err, result) => {
        if (err) return cb(err)
        if (typeof cb === 'function') return cb(null, result)
      }
    )
  },

  updateLocation: async function ({ latitude, longitude, ipAddress }, cb) {
    let url = ''
    if (latitude && longitude) {
      url = `user/me/update-location/${latitude}/${longitude}`
    } else if (ipAddress) {
      url = `user/me/update-location-by-ip/${ipAddress}`
    }
    await restRequest.patch(url, {}, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  getIdentity: async function ({ id }, cb) {
    await restRequest.get(`user/identify/${id}`, {}, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  getDomains: async function (payload, cb) {
    await restRequest.get(`general/domains`, payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  forgotPassword: async function (payload, cb) {
    await restRequest.patch('auth/forgot-password', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  deleteAccount: async function (payload, cb) {
    await restRequest.delete('user/me', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  checkValidUserName: async function (payload, cb) {
    await restRequest.post('user/check-username', payload, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },

  getSetting: async function (cb) {
    await restRequest.get('user/setting', {}, (err, result) => {
      if (err) return cb(err)
      if (typeof cb === 'function') return cb(null, result)
    })
  },
}

export default UserApi
