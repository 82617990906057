import { responseStatusCode, errorResponse } from '../../common/responseStatus'
import {
  GET_METHOD,
  POST_METHOD,
  PUT_METHOD,
  DELETE_METHOD,
  PATCH_METHOD,
} from '../../common/constants'
import _ from 'lodash'
import { headerConfig } from './config'

const createRestRequest = (isSocket = false) => {
  const baseURL = isSocket ? process.env.REACT_APP_CHATBOT_URL_API : process.env.REACT_APP_BASE_URL_API
  
  const handleResponse = async (response, cb, resBlob = false) => {
    if (response && response.status === responseStatusCode.success) {
      let result
      if (resBlob) {
        result = await response.blob()
        cb(null, { data: URL.createObjectURL(result) })
      } else {
        result = await response.json()
        cb(null, result)
      }
    } else {
      const errorResult = await response.json()
      switch (response.status) {
        case responseStatusCode.badRequest:
        case responseStatusCode.notFound:
        case responseStatusCode.unauthorized:
        case responseStatusCode.validationError:
          cb(errorResult)
          break
        default:
          cb(errorResult)
          break
      }
    }
  }

  const fetchAsync = async (url, config, cb, resBlob = false) => {
    try {
      const response = await fetch(url, config)
      await handleResponse(response, cb, resBlob)
    } catch (error) {
      cb(error)
    }
  }

  const fetchBase = async (method, event, data, cb, resBlob = false) => {
    const configBase = {
      headers: getHeaderConfig(data),
      method: method,
      body: data?.isFormData ? data.payload : JSON.stringify(data?.payload),
      credentials: 'include',
    }

    try {
      await fetchAsync(event, configBase, cb, resBlob)
    } catch (error) {
      cb(error)
    }
  }

  const get = async (event, data, cb) =>
    await fetchBase(GET_METHOD, baseURL + event, data, cb)

  const getBlob = async (event, data, cb) =>
    await fetchBase(GET_METHOD, baseURL + event, data, cb, true)

  const put = async (event, data, cb) =>
    await fetchBase(PUT_METHOD, baseURL + event, data, cb)

  const post = async (event, data, cb) =>
    await fetchBase(POST_METHOD, baseURL + event, data, cb)

  const patch = async (event, data, cb) =>
    await fetchBase(PATCH_METHOD, baseURL + event, data, cb)

  const del = async (event, data, cb) =>
    await fetchBase(DELETE_METHOD, baseURL + event, data, cb)

  const getHeaderConfig = (data) => {
    const headers = data?.headers ? { ...data.headers } : {}

    if (!data?.isFormData) {
      _.merge(headers, headerConfig.JSON_CONTENT_TYPE_HEADER)
    }

    return headers
  }

  return {
    get,
    getBlob,
    put,
    post,
    patch,
    delete: del,
  }
}

const getBaseRestRequest = (isSocket) => {
  return createRestRequest(isSocket)
}

export default getBaseRestRequest
