import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import inputTypes from '../components/common/inputTypes'
import {
  validateEmail,
  validatePassword,
  validateFileType,
  validateConfirmationCode,
  validateName,
} from '../helper/validationHelper'
import { useSettings } from './settingsContext'

const useFormBehavior = (props) => {
  const { fields, isDLField, handleFileChange } = props
  const formRef = useRef()
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})
  const [isChange, setIsChange] = useState(false)
  const [isValidateForm, setIsValidateForm] = useState(false)
  const { messages } = useSettings()
  const validators = {
    required: (value, field) =>
      _.trim(value) ? '' : messages['form.required'].replace('%s', field.labelError),
    email: (value) => validateEmail(value) ? messages['form.email'] : null,
    password: (value) => validatePassword(value) ? messages['form.password'] : null,
    match: (value, field) => {
      const relatedValue = formData[field.relatedField]
      const relatedName = _.find(
        fields,
        (x) => x.name === field.relatedField
      )?.labelError

      return _.isEqual(value, relatedValue)
        ? ''
        : messages['form.match'].replace('%r', relatedName).replace('%s', field.labelError)
    },
    otp: (value) => validateConfirmationCode(value) ? messages['form.code'] : null,
    positive: (value) => {
      const num = _.toNumber(value)
      return _.isInteger(num) && num > 0 ? '' : messages['form.positive']
    },
    maxLength: (value, field) =>
      value?.length <= field.maxLength
        ? ''
        : messages['form.maxLength'].replace('%s', field.maxLength),
    name: (value) => validateName(value) ? messages['form.name'] : null,
    duplicate: (value, field) =>
      !_.includes(field.duplicateList, value)
        ? ''
        : messages['form.duplicate'].replace('%s', field.labelError),
  }

  const validateField = (field, value, formErrors = null) => {
    if (_.get(field, 'name') && !_.isEmpty(_.get(field, 'validators'))) {
      const fieldName = field.name
      const rules = field.validators || []
      const supportedTypes = field.supportedTypes || []
      const result = []

      _.forEach(rules, (rule) => {
        let error = null
        if (_.isFunction(validators[rule])) {
          error = validators[rule](value, field)
        }

        if (error) result.push(error)
      })

      // Check file types
      if (!_.isEmpty(supportedTypes)) {
        let count = 0
        let message = ''

        _.forEach(supportedTypes, (type) => {
          const typeError = validateFileType(value, type)

          if (typeError) {
            count += 1
            message = typeError
          }
        })

        if (count === supportedTypes.length) {
          result.push(message)
        }
      }

      if (result.length > 0) {
        const errorMessage = result[0]
        if (formErrors) {
          formErrors[fieldName] = errorMessage
        }
        setErrors((pre) => ({ ...pre, [fieldName]: errorMessage }))
      } else {
        const updateErrors = { ...errors }
        delete updateErrors[fieldName]
        if (formErrors?.hasOwnProperty(fieldName)) {
          delete formErrors[fieldName]
        }
        setErrors(updateErrors)
      }
    }
  }

  const validateForm = (newValues = null, cb) => {
    const formErrors = { ...errors }
    _.forEach(fields, (field) => {
      const fieldName = field.name
      validateField(
        field,
        newValues ? newValues[fieldName] : formData[fieldName],
        formErrors
      )
    })
    setErrors(formErrors)
    if (_.isFunction(cb)) cb()
  }

  const handleChange = (e, index) => {
    const { name, value, tagName, type, files } = e.target

    if (name) {
      const field = _.find(fields, (x) => x.name === name)
      let fieldValue = value

      if (tagName === 'SELECT') {
        setIsChange(true)

        if (field) {
          validateField(field, fieldValue)
        }
      }

      if (type === 'file' && !_.isEmpty(files)) {
        setIsChange(true)
        fieldValue = files[0]

        if (field) {
          validateField(field, fieldValue)
        }

        if (_.isFunction(handleFileChange)) {
          handleFileChange(e)
        }
      }

      setFormData({ ...formData, [name]: fieldValue })
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target
    if (name) {
      setIsChange(true)
      const field = _.find(fields, (x) => x.name === name)

      if (field) {
        validateField(field, value)
        setFormData({ ...formData, [name]: value })
      }
    }
  }

  const resetForm = () => {
    if (formRef?.current) {
      formRef.current.reset()
    }

    setErrors({})
    setFormData({})
    setIsChange(false)
  }

  const initFormData = (newData, isValidateForm = false) => {
    const newFormData = {}

    _.forEach(fields, (field) => {
      newFormData[field.name] = newData[field.name]
    })

    setFormData(newFormData)
    setIsValidateForm(isValidateForm)
  }

  useEffect(() => {
    if (isValidateForm) {
      validateForm()
      setIsChange(true)
    }
  }, [isValidateForm])

  const renderFormFields = () => {
    if (_.isEmpty(fields)) return null

    return (
      <>
        {_.map(fields, (field, k) => {
          const inputType = field.inputType
          const fieldElement = field.fieldElement
          const fieldName = field.name
          const fieldError = _.get(errors, fieldName)
          const fieldValue = _.get(formData, fieldName)
          let FieldComponent = null

          if (inputType || fieldElement) {
            FieldComponent = inputTypes[inputType] || fieldElement

            if (isDLField) {
              return (
                <React.Fragment key={k}>
                  <dt>{field.label}</dt>
                  <dd>
                    <FieldComponent
                      {...field}
                      label=""
                      value={fieldValue}
                      error={fieldError}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </dd>
                </React.Fragment>
              )
            }

            return (
              <React.Fragment key={k}>
                <FieldComponent
                  {...field}
                  value={fieldValue}
                  error={fieldError}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </React.Fragment>
            )
          }

          return null
        })}
      </>
    )
  }

  const form = renderFormFields()
  const isFormValid = isChange && _.isEmpty(errors)

  return {
    form,
    formData,
    isChange,
    isFormValid,
    errors,
    resetForm,
    initFormData,
    validateField,
    validateForm,
    setFormData,
    handleBlur,
    handleChange,
  }
}

export default useFormBehavior
