/**
 * Validate the text is email or not
 * @param {text} value
 * @returns {text} - Error message or null
 */
export const validateEmail = (value) => {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)

  if (isValid) return null

  return 'The email is invalid'
}

/**
 * Validate the text is the valid password or not
 * @param {text} value
 * @returns {text} - Error message or null
 */
export const validatePassword = (value) => {
  const isValid =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/.test(value)

  if (isValid) return null

  return 'Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
}

/**
 * Validate the text is the valid email confirmation code or not
 * @param {text} value
 * @returns {text} - Error message or null
 */
export const validateConfirmationCode = (value) => {
  const isValid = /^[a-zA-Z0-9]{6}$/.test(value)

  if (isValid) return null

  return '6 characters possibly including digits, uppercase characters, lowercase characters'
}

/**
 * Validate the file type
 * @param {text} value
 * @returns {text} - Error message or null
 */
export const validateFileType = (file, type) => {
  let error = null

  if (type === 'excel') {
    const excelTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
    ]

    error = excelTypes.includes(file?.type)
      ? ''
      : 'The file extension(s) you have uploaded are not supported'
  } else if (type === 'word') {
    error =
      file?.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ? ''
        : 'The file extension(s) you have uploaded are not supported'
  } else if (type === 'pdf') {
    error =
      file?.type === 'application/pdf'
        ? ''
        : 'The file extension(s) you have uploaded are not supported'
  } else if (type === 'txt') {
    error =
      file?.type === 'text/plain'
        ? ''
        : 'The file extension(s) you have uploaded are not supported'
  }

  return error
}

/**
 * Validate the text is the valid first - last name for user
 * @param {text} value
 * @returns {text} - Error message or null
 */
export const validateName = (value) => {
  const isValid = /^[a-zA-Z0-9\s]*$/.test(value)

  if (isValid) return null

  return 'No special character, only space character accepted'
}
