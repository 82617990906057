import React from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import IconField from '../components/iconField'
import BuildSubPolicyLink from '../components/webChat/components/buildSubPolicyLink'

import './_signup.scss'

const SubPolicy = (props) => {
  const paragraphClass = 'flex flex-col gap-16'
  const HeaderClass = 'flex flex-col w-100'
  const subClass = 'flex flex-col w-100 gap-6'
  const grandsubClass = 'pl-8'
  const grand2subClass = 'pl-16'
  const titleClass = 'large'
  const subTitleClass = 'small'

  const buildDisclaimer = () => {
    return <>
      <div>
        Welcome to Tellme AI! By using our app, you acknowledge and agree to the terms in this Disclaimer.
        This Disclaimer aims to inform users about the limitations and potential risks of using our AI generative services.
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            1. General Information Only
          </div>
          <div className={subTitleClass}>
            The content generated by Tellme AI is intended solely for informational and entertainment purposes. It should not be used as a substitute for professional advice or judgment in any field, including but not limited to legal, medical, financial, or technical matters.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            2. No Warranties
          </div>
          <div className={subTitleClass}>
            We make no representations or warranties of any kind, express or implied, about the accuracy, completeness, reliability, suitability, or availability of the content generated by our AI for any purpose. Any reliance you place on the content generated by Tellme AI is strictly at your own risk.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            3. User Responsibility
          </div>
          <div className={subTitleClass}>
            You are solely responsible for any actions taken based on the content generated by Tellme AI. By using this app, you agree to use the content responsibly and acknowledge that Tellme AI does not bear liability for any consequences resulting from its use.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            4. AI Limitations
          </div>
          <div className={subTitleClass}>
            As an AI-based application, Tellme AI may produce content that is inaccurate, biased, outdated, or otherwise flawed. Our AI model is continuously updated to improve accuracy; however, no AI is infallible, and there may be unexpected errors or inconsistencies in the generated content.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            5. Limitation of Liability
          </div>
          <div className={subTitleClass}>
            To the fullest extent permitted by law, Tellme AI, its owners, developers, and affiliates disclaim all liability for any direct, indirect, incidental, or consequential losses or damages incurred by you or any third party arising out of or in connection with your use of the app or its content.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            6. Intellectual Property
          </div>
          <div className={subTitleClass}>
            Any content generated through Tellme AI may be subject to intellectual property laws. You are responsible for ensuring that the content you use or share does not violate third-party intellectual property rights, and Tellme AI disclaims all liability in such instances.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            7. Changes to This Disclaimer
          </div>
          <div className={subTitleClass}>
            We may update this Disclaimer periodically to reflect changes in our app or policies.
          </div>
          <div className={subTitleClass}>
            Continued use of the app following these changes signifies acceptance of the revised Disclaimer.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            8. Contact Us
          </div>
          <div className={subTitleClass}>
            If you have questions or need further information about this Disclaimer, please contact us at info@tellme.club.
          </div>
        </div>
      </div>
    </>
  }

  const buildContent = () => {
    return <>
      <div>
        At Tellme AI, we aim to foster a positive and responsible environment for all users. This Content Policy outlines acceptable use guidelines to ensure that all content generated and shared through our app aligns with our values and community standards. By using Tellme AI, you agree to adhere to this policy.
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            1. Prohibited Content
          </div>
          <div className={subTitleClass}>
            You are not permitted to use Tellme AI to generate, post, or share content that includes or promotes:
          </div>
        </div>
        <div className={subClass}>
          <div className={grandsubClass}>
            Illegal Activities: Any content that encourages or facilitates illegal acts, including fraud, hacking, or drug trafficking. Hate Speech.
          </div>
          <div className={grandsubClass}>
            Discrimination: Content that promotes hate, violence, or discrimination against individuals or groups based on race, ethnicity, religion, gender, sexual orientation, disability, or any other protected characteristic.
          </div>
          <div className={grandsubClass}>
            Violence and Threats: Content depicting or inciting violence, self-harm, threats, or dangerous activities.
          </div>
          <div className={grandsubClass}>
            Explicit and Adult Content: Pornographic, sexually explicit, or obscene material, or anything not suitable for general audiences.
          </div>
          <div className={grandsubClass}>
            Harassment and Bullying: Content that harasses, intimidates, or bullies others. Misinformation and Manipulation: False information or deceptive content intended to mislead others on serious matters, including health, finance, and public safety.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            2. Intellectual Property Rights
          </div>
          <div className={subTitleClass}>
            You agree not to generate or distribute content that infringes upon intellectual property rights, including copyrighted material, trademarks, and patents, unless you have explicit permission to use such material. Any generated content that mimics or reproduces known brands, logos, or characters may also be restricted to protect intellectual property rights.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            3. Privacy and Personal Information
          </div>
          <div className={subTitleClass}>
            Do not use Tellme AI to generate or share personal information about individuals without their consent, including contact details, identification numbers, or other sensitive information. Any content involving the personal data of others must comply with applicable data protection laws, including but not limited to GDPR and CCPA.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            4. Responsible Use of AI Content
          </div>
          <div className={subTitleClass}>
            Tellme AI’s AI-generated content is designed for creative or informational purposes only. Users must not present AI-generated content as factual or use it to deceive others. AI-generated content should not be used for critical decision-making purposes, including legal, medical, or financial advice.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            5. Enforcement
          </div>
          <div className={subTitleClass}>
            We reserve the right to review, restrict, or remove any content that we believe violates this policy. Users who repeatedly violate this Content Policy may have their accounts suspended or terminated. If you come across content that you believe violates this policy, please report it to us at info@tellme.club.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            6. Changes to the Content Policy
          </div>
          <div className={subTitleClass}>
            This Content Policy may be updated periodically to address emerging issues or to improve community standards. Users will be notified of significant changes, and continued use of Tellme AI following these changes constitutes acceptance of the revised policy.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            7. Contact Us
          </div>
          <div className={subTitleClass}>
            If you have questions or concerns about this Content Policy, please reach out to us at info@tellme.club. This policy is designed to encourage safe, respectful content generation and sharing, fostering a positive user experience. Adjusting this based on your app's particular content generation capabilities and intended user base will ensure clarity and alignment with your app’s standards.
          </div>
        </div>
      </div>
    </>
  }

  const buildEULA = () => {
    return <>
      <div>
        This End User License Agreement (“Agreement”) is a legal agreement between you (“User” or “you”) and Tellme AI (“we,” “our,” or “us”) for the use of the Tellme AI application, including any related software, content, and services (collectively, the “App”). By downloading, installing, or using the App, you agree to be bound by the terms and conditions of this Agreement.
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            1. License Grant
          </div>
          <div className={subTitleClass}>
            We grant you a limited, non-exclusive, non-transferable, revocable license to use Tellme AI for personal or internal business purposes, subject to the terms of this Agreement. This license does not grant you any ownership rights to the App or its content.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            2. Restrictions
          </div>
          <div className={subTitleClass}>
            You agree not to: Reverse engineer, decompile, disassemble, or attempt to discover the source code or algorithms of the App. Modify, adapt, translate, or create derivative works based on the App or any portion thereof. Use the App to develop or create a competitive product. Rent, lease, sublicense, distribute, sell, or otherwise commercially exploit the App. Circumvent any technical limitations in the App or access features not made available to you by default.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            3. Ownership
          </div>
          <div className={subTitleClass}>
            The App, including all intellectual property rights, content, and software, is owned by Tellme AI and is protected by copyright, trademark, and other laws. This Agreement does not grant you any ownership interest in the App, nor any rights other than those explicitly granted in this Agreement.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            4. User Content
          </div>
          <div className={subTitleClass}>
            Generated Content: You are solely responsible for the content you generate through the App, including ensuring that it complies with applicable laws and our Content Policy.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            5. Privacy
          </div>
          <div className={subTitleClass}>
            Our Privacy Policy governs the collection, use, and sharing of personal information. By using the App, you agree to the collection and use of your information as outlined in our Privacy Policy.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            6. Updates and Modifications
          </div>
          <div className={subTitleClass}>
            We may release updates, enhancements, or modifications to the App at any time, and you agree that these updates are governed by this Agreement unless otherwise stated. We are not obligated to provide support, updates, or maintenance.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            7. Termination
          </div>
          <div className={subTitleClass}>
            If you have any questions or concerns regarding this Cookie Policy, please contact us at info@tellme.club. This Cookie Policy outlines how cookies are used within your app, giving users control and transparency over their preferences. Be sure to review and adapt it based on your actual cookie practices and local regulatory requirements.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            8. Disclaimer of Warranties
          </div>
          <div className={subTitleClass}>
            The App is provided “as is” and “as available,” without warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the App will meet your requirements, operate uninterrupted, or be error-free.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            9. Limitation of Liability
          </div>
          <div className={subTitleClass}>
            To the maximum extent permitted by law, Tellme AI and its affiliates will not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of or inability to use the App, even if advised of the possibility of such damages.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            10. Indemnification
          </div>
          <div className={subTitleClass}>
            You agree to indemnify, defend, and hold harmless Tellme AI, its affiliates, officers, directors, and employees from any claims, damages, losses, liabilities, or expenses (including legal fees) arising from your use of the App, violation of this Agreement, or infringement of any third-party rights.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            11. Governing Law
          </div>
          <div className={subTitleClass}>
            This Agreement is governed by and construed under the laws of [Jurisdiction]. Any disputes arising from this Agreement will be subject to the exclusive jurisdiction of the courts in [Jurisdiction].
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            12. Changes to this Agreement
          </div>
          <div className={subTitleClass}>
            We may update this Agreement from time to time. We will notify you of significant changes by posting a revised version on our website or app. Your continued use of the App after changes are posted signifies acceptance of the revised Agreement.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            13. Contact Information
          </div>
          <div className={subTitleClass}>
            If you have questions or concerns regarding this Agreement, please contact us at info@tellme.club. This EULA provides a clear understanding of the usage rights and limitations associated with your app. Tailoring this to specific functionalities and local legal requirements will help protect your app and ensure a clear, respectful relationship with users.
          </div>
        </div>
      </div>
    </>
  }

  const buildCookie = () => {
    return <>
      <div>
        This Cookie Policy explains how Tellme AI (“we,” “our,” or “us”) uses cookies and similar tracking technologies to recognize you when you visit or use our app. By using Tellme AI, you agree to the use of cookies as described in this policy.
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            1. What Are Cookies?
          </div>
          <div className={subTitleClass}>
            Cookies are small text files stored on your device (computer, tablet, or mobile phone) by your browser when you access websites or apps. They enable us to recognize your device, remember your preferences, and enhance your experience.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            2. Types of Cookies We Use
          </div>
          <div className={subTitleClass}>
            We use the following types of cookies in our app:
          </div>
        </div>
        <div className={subClass}>
          <div className={grandsubClass}>
            Essential Cookies: These cookies are necessary for the operation of our app. They enable core functions like user authentication, security, and access to certain features. Without these cookies, services you have requested cannot be provided.
          </div>
          <div className={grandsubClass}>
            Performance and Analytics Cookies: These cookies help us understand how users interact with our app, allowing us to measure usage and improve performance. For example, they help us know which pages are most popular or if users encounter any errors.
          </div>
          <div className={grandsubClass}>
            Functionality Cookies: These cookies remember choices you make (e.g., language preferences) to provide a more personalized experience.
          </div>
          <div className={grandsubClass}>
            Advertising and Targeting Cookies: These cookies track browsing habits to deliver advertisements more relevant to you and your interests. They may also limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            3. Third-Party Cookies
          </div>
          <div className={subTitleClass}>
            We may allow certain third-party services (such as analytics providers and advertisers) to place cookies on our app to collect data on our behalf. These third parties may have their own privacy policies and cookie policies. We do not have control over their use of cookies.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            4. How We Use Cookies
          </div>
          <div className={subTitleClass}>
            We use cookies to:
          </div>
        </div>
        <div className={subClass}>
          <div className={grandsubClass}>
            Provide essential functionality and secure login features. Analyze usage data to enhance the user experience. Remember user preferences to improve app functionality. Deliver personalized advertisements based on your browsing behavior.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            5. Managing and Disabling Cookies
          </div>
          <div className={subTitleClass}>
            You can manage or disable cookies at any time by adjusting your browser settings. However, please note that disabling essential cookies may affect your ability to use some parts of our app.
          </div>
          <div className={subTitleClass}>
            Instructions for Managing Cookies:
          </div>
        </div>
        <div className={subClass}>
          <div className={grandsubClass}>
            Browser Settings: Most web browsers allow you to manage cookies through their settings. Opting Out of Targeted Advertising: You may opt out of targeted advertising cookies by adjusting your preferences (for users in the EU).
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            6. Changes to This Cookie Policy
          </div>
          <div className={subTitleClass}>
            We may update this Cookie Policy from time to time to reflect changes in our practices or for legal, regulatory, or operational reasons. Any changes will be posted on this page, and the “Effective Date” will be updated. Continued use of our app following any changes constitutes acceptance of those changes.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            7. Contact Us
          </div>
          <div className={subTitleClass}>
            If you have any questions or concerns regarding this Cookie Policy, please contact us at info@tellme.club. This Cookie Policy outlines how cookies are used within your app, giving users control and transparency over their preferences. Be sure to review and adapt it based on your actual cookie practices and local regulatory requirements.
          </div>
        </div>
      </div>
    </>
  }

  const buildRefund = () => {
    return <>
      <div>
        Thank you for using Tellme AI! We strive to ensure a positive experience with our app, and we understand that sometimes users may need to request refunds. This Refund Policy outlines the conditions under which refunds may be provided for purchases made through Tellme AI.
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            1. Eligibility for Refunds
          </div>
          <div className={subTitleClass}>
            Refunds are available under the following circumstances:
          </div>
        </div>
        <div className={subClass}>
          <div className={grandsubClass}>
            Technical Issues: If you encounter a technical issue that prevents you from using the app, please contact our support team. If we cannot resolve the issue, you may be eligible for a full or partial refund.
          </div>
          <div className={grandsubClass}>
            Accidental Purchase: If you have accidentally purchased a subscription or feature and notify us within 24 hours of the purchase, we may issue a refund as a courtesy. Unsatisfactory Experience: If you’re not satisfied with your experience, please reach out within [number of days, e.g., 14 days] of purchase to discuss your concerns. Refunds may be considered on a case-by-case basis.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            2. Non-Refundable Situations
          </div>
          <div className={subTitleClass}>
            Refunds will not be provided in the following cases:
          </div>
        </div>
        <div className={subClass}>
          <div className={grandsubClass}>
            Completed Services: If you have used the app extensively or completed a large portion of a subscription period, refunds will generally not be granted.
          </div>
          <div className={grandsubClass}>
            Change of Mind: Refunds are not available for changes of mind or dissatisfaction without cause.
          </div>
          <div className={grandsubClass}>
            Violation of Terms of Use: If your account is terminated or restricted due to violations of our Terms of Use, you will not be eligible for a refund.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            3. Subscription Cancellations
          </div>
          <div className={subTitleClass}>
            Auto-Renewing Subscriptions: If you have a subscription, you can cancel at any time by going to your account settings. Your subscription will remain active until the end of the current billing period, and you will not receive a refund for any unused portion.
          </div>
        </div>
        <div className={subClass}>
          <div className={grandsubClass}>
            Free Trials: If you start a free trial and do not wish to be charged, please cancel the subscription before the trial period ends to avoid automatic charges.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            4. How to Request a Refund
          </div>
          <div className={subTitleClass}>
            To request a refund, please contact our customer support team at info@tellme.club with the following details: Your account username or registered email. Purchase date and a description of the issue. Any relevant documentation or screenshots that can help us process your request.
          </div>
          <div className={subTitleClass}>
            Our support team will review your request and respond within 5 business days. Please note that refunds may take additional time to process, depending on your payment provider.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            5. Refund Processing
          </div>
          <div className={subTitleClass}>
            Approved refunds will be processed back to the original payment method used during purchase. Refunds may take between [number of days, e.g., 5-10 business days] to reflect in your account, depending on your bank or payment provider.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            6. Changes to This Refund Policy
          </div>
          <div className={subTitleClass}>
            We may update this Refund Policy from time to time to reflect changes in our practices or for legal, regulatory, or operational reasons. Continued use of our app following any changes constitutes acceptance of the revised policy.
          </div>
        </div>
      </div>
      <div className={paragraphClass}>
        <div className={HeaderClass}>
          <div className={titleClass}>
            7. Contact Us
          </div>
          <div className={subTitleClass}>
            If you have any questions or need further assistance with a refund, please contact our customer support team at info@tellme.club.
          </div>
        </div>
      </div>
    </>
  }

  const items = {
    'disclaimer': {
      title: 'DISCLAIMER',
      render: buildDisclaimer,
    },
    'content-policy': {
      title: 'CONTENT POLICY',
      render: buildContent,
    },
    'eula': {
      title: 'END USER LICENSE AGREEMENT (EULA)',
      render: buildEULA,
    },
    'cookie-policy': {
      title: 'COOKIE POLICY',
      render: buildCookie,
    },
    'refund-policy': {
      title: 'REFUND POLICY',
      render: buildRefund,
    }
  }

  const onRef = (node) => {
    if (node && node?.scrollTop) node?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  let { subpath } = useParams()
  subpath = _.some(items, (v, k) => k === subpath) ? subpath : 'disclaimer'
  const effectedDate = 'Effective Date: December 5 2024'

  return (
    <div ref={onRef} className="site-layout scrollbar overflow-y">
      <div className="terms-container policy flex flex-col align-center">
        <div className="flex flex-col align-center gap-56 pb-40">
          <div className="flex flex-col align-center gap-20">
            <IconField name="logoM" />
            <div className="flex flex-col align-center gap-6">
              <div className='xlarge text-center'>{items[subpath]?.title}</div>
              <div className='small'>{effectedDate}</div>
            </div>
          </div>
          <div className="flex flex-col gap-40 w-100 p-20">
            {items[subpath]?.render()}
          </div>
          <BuildSubPolicyLink />
        </div>
      </div>
    </div>
  )
}

export default SubPolicy
