import React from 'react'
import _ from 'lodash'

import { useSettings } from '../../hooks/settingsContext'
import { useAuth } from '../../hooks/authProvider'
import IconField from '../iconField'

const SiteChatHeader = ({ totalQuestions, converId, setIsOpenSharingModal }) => {
  const { setOpenSettings, messages } = useSettings()
  const auth = useAuth()
  const isLogin = auth?.isLogin()

  const handleSetting = () => setOpenSettings(true)

  const handleLogout = () => {
    auth.logoutAction()
  }

  const handleClickSharing = () => {
    setIsOpenSharingModal(true)
  }

  const panelItems = [
    {
      key: 'panel-counter',
      title: '',
      name: 'counter',
      alt: '',
      enable: true,
    },
    {
      key: 'panel-share',
      name: 'sharing',
      enable: Boolean(converId) && isLogin,
      handleClick: handleClickSharing
    },
    { key: 'divider-counter', enable: true },
    {
      key: 'panel-setting',
      title: messages['siteHeader.settings.alt'],
      handleClick: handleSetting,
      name: 'setting',
      alt: messages['siteHeader.settings.alt'],
      enable: true,
    },
    {
      key: 'panel-avatar',
      title: '',
      handleClick: null,
      name: 'avatar',
      alt: '',
      enable: isLogin,
    },
    { key: 'divider', enable: isLogin },
    {
      key: 'panel-logout',
      title: messages['siteHeader.logout.alt'],
      handleClick: handleLogout,
      name: 'logout',
      alt: messages['siteHeader.logout.alt'],
      enable: isLogin,
    },
  ]

  return (
    <div className={`site-header`}>
      <div className="header-panel flex flex-row align-center">
        {_.map(panelItems, (item) => {
          if (!item.enable)
            return <React.Fragment key={item.key}></React.Fragment>
          if (item.name === 'counter')
            return (
              <span
                key={item.key}
                className="total-questions hidden"
                title={messages['siteHeader.totalQuestions'].replace(
                  '%s',
                  totalQuestions
                )}
              >
                {messages['siteHeader.totalQuestions'].replace(
                  '%s',
                  totalQuestions
                )}
              </span>
            )
            if (item.name === 'sharing')
              return (
                <button
                  key={item.key}
                  className="btn btn-secondary sharing-button flex gap-4 align-center c-pointer"
                  onClick={item.handleClick}
                >
                  <IconField name="sharing" />
                  <span>{messages['siteHeader.sharing']}</span>
                </button>
              )
          if (_.includes(item.key, 'divider'))
            return <div key={item.key} className="divider-rotate"></div>
          return (
            <button
              key={item.key}
              className={`btn btn-icon`}
              title={item.title}
              onClick={item.handleClick}
              style={{ cursor: !item.handleClick ? 'unset' : 'pointer' }}
            >
              <IconField name={item.name} />
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default SiteChatHeader
