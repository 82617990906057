import React from 'react'
import { ReactComponent as Logo } from '../assets/icons/logo.svg'
import { ReactComponent as LogoH } from '../assets/icons/logo_h.svg'
import { ReactComponent as Menu } from '../assets/icons/menu.svg'
import { ReactComponent as Editing } from '../assets/icons/editing.svg'
import { ReactComponent as LogoM } from '../assets/icons/logo_m.svg'
import { ReactComponent as Avatar } from '../assets/icons/avatar.svg'
import { ReactComponent as Logout } from '../assets/icons/logout.svg'
import { ReactComponent as Setting } from '../assets/icons/setting.svg'
import { ReactComponent as Location } from '../assets/icons/location.svg'
import { ReactComponent as Close } from '../assets/icons/close.svg'
import { ReactComponent as Error } from '../assets/icons/error.svg'
import { ReactComponent as Send } from '../assets/icons/send-filled.svg'
import { ReactComponent as LogoGoogle } from '../assets/icons/logo_google.svg'
import { ReactComponent as LogoApple } from '../assets/icons/logo_apple.svg'
import { ReactComponent as Copy } from '../assets/icons/copy.svg'
import { ReactComponent as Loading } from '../assets/icons/loading.svg'
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg'
import { ReactComponent as FlagUSA } from '../assets/icons/flag_usa.svg'
import { ReactComponent as ChatBot } from '../assets/icons/chatbot-icon.svg'
import { ReactComponent as EyeLine } from '../assets/icons/eye_line.svg'
import { ReactComponent as Warning } from '../assets/icons/warning.svg'
import { ReactComponent as BubbleConver } from '../assets/icons/bubble_conver.svg'
import { ReactComponent as Actions } from '../assets/icons/actions.svg'
import { ReactComponent as IconError } from '../assets/icons/icon_error.svg'
import { ReactComponent as IconInfo } from '../assets/icons/icon_info.svg'
import { ReactComponent as IconSuccess } from '../assets/icons/icon_success.svg'
import { ReactComponent as IconWarning } from '../assets/icons/icon_warning.svg'
import { ReactComponent as ThreeDots } from '../assets/icons/actions.svg'
import { ReactComponent as Translate } from '../assets/icons/translate.svg'
import { ReactComponent as Speaker } from '../assets/icons/speaker.svg'
import { ReactComponent as Refresh } from '../assets/icons/refresh.svg'
import { ReactComponent as Rename } from '../assets/icons/rename.svg'
import { ReactComponent as Archive } from '../assets/icons/archive.svg'
import { ReactComponent as Delete } from '../assets/icons/delete.svg'
import { ReactComponent as Playing } from '../assets/icons/playing.svg'
import { ReactComponent as Micro } from '../assets/icons/micro.svg'
import { ReactComponent as Profile } from '../assets/icons/profile.svg'
import { ReactComponent as SettingTab } from '../assets/icons/setting_tab.svg'
import { ReactComponent as EditInfo } from '../assets/icons/edit_info.svg'
import { ReactComponent as Lookup } from '../assets/icons/lookup.svg'
import { ReactComponent as Unarchive } from '../assets/icons/unarchive.svg'
import { ReactComponent as DeleteArc } from '../assets/icons/delete-arc.svg'
import { ReactComponent as AudioLoading } from '../assets/icons/audio_loading.svg'
import { ReactComponent as Like } from '../assets/icons/like.svg'
import { ReactComponent as Liked } from '../assets/icons/liked.svg'
import { ReactComponent as TranslateVN } from '../assets/icons/translate_vn.svg'
import { ReactComponent as Insurance } from '../assets/icons/insurance.svg'
import { ReactComponent as Dentist } from '../assets/icons/dentist.svg'
import { ReactComponent as Estate } from '../assets/icons/estate.svg'
import { ReactComponent as Finance } from '../assets/icons/finance.svg'
import { ReactComponent as Immigration } from '../assets/icons/immigration.svg'
import { ReactComponent as Loan } from '../assets/icons/loan.svg'
import { ReactComponent as Medical } from '../assets/icons/medical.svg'
import { ReactComponent as Protection } from '../assets/icons/protection.svg'
import { ReactComponent as Check } from '../assets/icons/check.svg'
import { ReactComponent as Chatting } from '../assets/icons/chatting-icon.svg'
import { ReactComponent as Sharing } from '../assets/icons/sharing.svg'
import { ReactComponent as Link } from '../assets/icons/link.svg'
import { ReactComponent as LinkCopy } from '../assets/icons/link_copy.svg'
import { ReactComponent as Facebook } from '../assets/icons/facebook_logo.svg'

const iconTypes = {
  logo: Logo,
  logoH: LogoH,
  logoM: LogoM,
  menu: Menu,
  editing: Editing,
  avatar: Avatar,
  logout: Logout,
  setting: Setting,
  location: Location,
  close: Close,
  error: Error,
  send: Send,
  logoGoogle: LogoGoogle,
  logoApple: LogoApple,
  copy: Copy,
  loading: Loading,
  arrowDown: ArrowDown,
  flagUSA: FlagUSA,
  chatBot: ChatBot,
  eyeLine: EyeLine,
  warning: Warning,
  bubbleConver: BubbleConver,
  actions: Actions,
  iconError: IconError,
  iconInfo: IconInfo,
  iconSuccess: IconSuccess,
  iconWarning: IconWarning,
  threeDots: ThreeDots,
  translate: Translate,
  speaker: Speaker,
  refresh: Refresh,
  rename: Rename,
  archive: Archive,
  delete: Delete,
  playing: Playing,
  micro: Micro,
  profile: Profile,
  settingTab: SettingTab,
  editInfo: EditInfo,
  lookup: Lookup,
  unarchive: Unarchive,
  deleteArc: DeleteArc,
  audioLoading: AudioLoading,
  like: Like,
  liked: Liked,
  translateVN: TranslateVN,
  insurance: Insurance,
  dentist: Dentist,
  estate: Estate,
  finance: Finance,
  immigration: Immigration,
  loan: Loan,
  medical: Medical,
  protection: Protection,
  check: Check,
  chatting: Chatting,
  sharing: Sharing,
  link: Link,
  linkCopy: LinkCopy,
  facebook: Facebook,
}

const IconField = ({ name, wrapper, ...props }) => {
  const Icon = iconTypes[name]
  if (!Icon) return <></>
  if (wrapper)
    return (
      <div {...wrapper}>
        <Icon {...props} />
      </div>
    )
  return <Icon {...props} />
}

export default IconField
