import React from 'react'
import _ from 'lodash'

import IconField from '../iconField'
import { useSettings } from '../../hooks/settingsContext'

import './_common.scss'

const CommonModal = (props) => {
  const {
    id,
    name,
    isOpen,
    title,
    modalClass,
    description,
    element,
    noFooter,
    noHeader,
    handleClose,
    handleConfirm,
    handleCancel,
    handleRenderCustomFooter,
    confirmText,
    cancelText,
  } = props

  const { messages } = useSettings()

  const renderModalActions = () => {
    const confirmBtn = _.isFunction(handleConfirm) ? (
      <button className="btn btn-primary rad-6" onClick={handleConfirm}>
        {confirmText}
      </button>
    ) : null
    const cancelBtn = _.isFunction(handleCancel) ? (
      <button className="btn btn-secondary rad-6" onClick={handleCancel}>
        {cancelText}
      </button>
    ) : null
    const customFooter = _.isFunction(handleRenderCustomFooter)
      ? handleRenderCustomFooter()
      : null

    return (
      <>
        {cancelBtn}
        {confirmBtn}
        {customFooter}
      </>
    )
  }

  const clickOutside = (e) => {
    if ([...e.target.classList].includes('modal-popup'))
      handleClose?.() || handleCancel?.()
  }

  if (!isOpen) return <></>

  return (
    <div className={`modal-popup active`} onMouseUp={clickOutside}>
      <div id={id} name={name} className={`tm-modal small-modal ${modalClass}`}>
        {!noHeader && (
          <div className="modal-header flex">
            <div></div>
            <div className="modal-title">{title}</div>
            <div className="modal-close">
              {_.isFunction(handleCancel ?? handleClose) ? (
                <IconField
                  title={messages['modal.close']}
                  name="close"
                  onClick={handleCancel ?? handleClose}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <div className="modal-content">
          {description ? (
            <p className="description text-secondary">{description}</p>
          ) : null}
          {element}
        </div>
        {!noFooter && (
          <footer className="modal-footer">{renderModalActions()}</footer>
        )}
      </div>
    </div>
  )
}

export default CommonModal
