import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Signup from './pages/signup'
import WebChat from './pages/webChat'
import AuthProvider from './hooks/authProvider'
import FlashProvider from './hooks/useFlash'
import Confirmation from './pages/confirmation'
import ForgotPassword from './pages/forgotPassword'
import ResetPassword from './pages/resetPassword'
import TermsOfUse from './pages/termsOfUse'
import PrivacyPolicy from './pages/privacyPolicy'
import HomePage from './pages/homePage'
import SubPolicy from './pages/subPolicy'
import SharingPage from './pages/sharing'

import 'bootstrap/dist/css/bootstrap.css'
import './pages/_pages.scss'
import './common/scss/_responsive.scss'

function App() {
  return (
    <Router>
      <FlashProvider>
        <AuthProvider>
          <Routes>
            <Route exact path="/confirmation" element={<Confirmation />} />
            <Route exact path="/reset-password/p" element={<ResetPassword />} />
            <Route
              exact
              path="/reset-password/m"
              element={<ForgotPassword />}
            />
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/chat" element={<WebChat />} />
            <Route exact path="/terms-of-use" element={<TermsOfUse />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/sub-policy/:subpath?" element={<SubPolicy />} />
            <Route exact path="/login" element={<Signup scope="login" />} />
            <Route exact path="/signup" element={<Signup scope="signup" />} />
            <Route exact path="/share/:shareId" element={<SharingPage/>} />
          </Routes>
        </AuthProvider>
      </FlashProvider>
    </Router>
  )
}

export default App
