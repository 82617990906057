import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import Markdown from 'react-markdown'

import ChatbotApi from '../../api/chatbot/chatbotAPI'
import { useAuth } from '../../hooks/authProvider'
import { useSettings } from '../../hooks/settingsContext'
import Welcome from './components/welcome'
import ChatBox from './components/chatBox'
import Suggestions from './components/suggestions'
import SiteChatHeader from './siteHeader'
import IconField from '../iconField'
import ButtonField from '../buttonField'
import {
  DEFAULT_ANSWER,
  DEFAULT_CHAT_ID,
  FLASH_TYPE,
  QUESTION_TYPE,
  SPEAKER_STATUS,
  locales
} from '../../common/constants'
import useWebSocket from '../../common/socket'
import { useFlash } from '../../hooks/useFlash'
import AddMoreModal from '../common/popup/addMoreModal'

import './_siteChat.scss'
import SharingModal from '../common/popup/sharingModal'
import ReachLimitModal from '../common/popup/reachLimitModal'
import SocketApi from '../../api/socket/socketAPI'
import { responseStatusCode } from  '../../common/responseStatus'
import { settingModuleMapping } from '../../common/enum'

const SiteChat = ({
  currentChat,
  setCurrentChat,
  convers,
  setConvers,
  inputRef,
  expanding,
  setExpanding,
  prompts,
  setPrompts,
  handleNewChat
}) => {
  const { domainId, chatMessages, is_reach_chat_limit, message, converId, sharingLink } =
    currentChat
  const flash = useFlash()
  const [loading, setLoading] = useState(false)
  const [selectedPrompt, setSelectedPrompt] = useState('')
  const [readmoreBusState, setReadmoreBusState] = useState({
    isOpen: false,
    messageId: '',
    domainName: '',
  })
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [isOpenSharingModal, setIsOpenSharingModal] = useState(false)
  const [limitModal, setLimitModal] = useState({ isOpen: false, modalName: '', description: ''})
  const { isLogin, user, setUser, getSetting } = useAuth()
  const showTranslateIcon = _.get(user, 'setting_module.using_translation')
  const showSpeakIcon = _.get(user, 'setting_module.using_text_to_speech')
  const { messages } = useSettings()
  const audioRef = useRef({
    currentId: null,
    audio: new Audio(),
    needRefresh: false,
  })
  const currentUserLanguage = _.get(user, 'user_info.language')
  const isDisableGoship = _.get(currentChat, 'is_reached_chatting_limit', false) || (_.get(user, "user_chat_limit.message", "") !== "") || loading

  const isDisableTTS = _.get(currentChat, 'is_reached_tts_limit', false) || (_.get(user, "user_chat_limit.remaining_token_today", 1) === 0)
  const listRef = useRef()
  const mainChatRef = useRef()
  const [domains, setDomains] = useState([])

  const { sendMessage, socketSid } = useWebSocket(user, {
    onMessage: (msg) => updateChatMessages(msg),
    onLockChat: (status) => setLoading(status),
    onGetSuggestions: (fields = {}) =>
      setCurrentChat((prev) => { 
        if (!_.isNil(prev.converId) && !_.isEmpty(prev.converId) && prev.converId === fields.conversationId) {
          return {...prev, ...fields }
        }
        return prev
      }),
    onGetTotal: ({ totalQuestions }) => setTotalQuestions(totalQuestions),
    onLimitExceededError: (data) => handleLimitExceededError(data),
    onLimitUserResponse: (data) => handleLimitUserResponse(data),
  })

  const handleLimitUserResponse = (data) => {
    updateChatMessages({ ...data, isFinish: true })
    if (data && _.get(data, "message", "")) {
      getSetting()
    }
  }

  const handleRemoveNewChat = () => {
    setCurrentChat((prev) => ({
      ...prev,
      chatMessages: _.omit(prev.chatMessages, DEFAULT_CHAT_ID)
    }))
  }

  const handleLimitExceededError = (data) => {
    getSetting()
    handleRemoveNewChat()
    flash.showFlash(FLASH_TYPE.Error, { detail: data })
  }

  useEffect(() => {
    ChatbotApi.getDomains('premade_question', null, (err, res) => {
      const dataDomains = _.get(res, 'data', [])
      if (!err && res && dataDomains) {
        setDomains(dataDomains)
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
    })
  }, [])

  const handleScroll = () => {
    if (listRef?.current) {
      const { scrollHeight, scrollTop, clientHeight } = listRef.current || {}
      // Check if the element is at the bottom
      if (scrollTop + clientHeight < scrollHeight) {
        // scroll the element to the bottom
        listRef.current.scrollTop = scrollHeight - clientHeight
      }
    }
  }

  useEffect(() => {
    setCurrentChat((prev) => ({...prev, suggestedQuestion: [] }))
    if (audioRef.current.currentId && audioRef.current?.audio.currentTime > 0) {
      audioRef.current?.audio?.pause()
      audioRef.current.currentId = null
      audioRef.current.needRefresh = false
    }
    handleScroll()
  }, [converId])

  useEffect(() => {
    if (loading) handleScroll()

    if (mainChatRef.current) {
      const allClassNeedToCheck = [
        "chat-messages",
        "content w-100",
        "main-answer-contents",
        "advertising",
        "main-chat",
        "markdown-answer"
      ]
      const observer = new MutationObserver((mutationsList) => {
        if (!loading) return
        for (const mutation of mutationsList) {
          const { target } = mutation || {} 
          if (!(target instanceof Element)) continue
          // case 1: in allClassNeedToCheck
          const needScrollClass = allClassNeedToCheck.some((className) => target.className === className)
          // case 2: in markdown
          const checkIsNestedMarkdown = () => !!(target.closest('.markdown-answer'))
          if (needScrollClass || checkIsNestedMarkdown()) handleScroll()
        }
      })

      observer.observe(mainChatRef.current, {
        childList: true,
        attributes: false,
        subtree: true,
      })
      return () => {
        observer.disconnect()
      }
    }
  }, [loading])

  const updateChatMessages = ({
    obj,
    isReplace,
    isGenerating,
    isFinish,
    isRemove,
    isAds,
    isReadmore,
    ...rest
  }) => {
    if (!_.isEmpty(obj)) {
      setCurrentChat((prev) => {
        let newChats = { ...prev.chatMessages }
        if (isReplace || isGenerating || isRemove) {
          const { id, text } = obj
          newChats = _.transform(newChats, (r, v, k) => {
            if (k === DEFAULT_CHAT_ID) {
              if (!isRemove) {
                let answer = { type: 'answer', text: v?.answer?.text }
                if (isGenerating) answer.text = text
                r[id] = { ...v, id: id, answer, answer_trans: { ...answer } }
              }
            } else {
              r[k] = v
            }
          })
        } else {
          newChats[obj.id] = obj
        }
        return { ...prev, chatMessages: newChats, ...rest, domainId: null }
      })

      if (
        isReplace &&
        obj?.conversation_id
      ) {
        setConvers((prev) => ({
          ...prev,
          needUpdate: !_.some(prev?.list, (v) => _.some(v, { id: obj?.conversation_id }))
        }))
      }
    } else if (isAds) {
      setCurrentChat((prev) => ({
        ...prev,
        chatMessages: _.transform(
          prev.chatMessages,
          (r, v, k) =>
            (r[k] = {
              ...v,
              advertising: k === rest.id ? rest?.advertising : v?.advertising,
            })
        ),
      }))
    } else if (isFinish) {
      setCurrentChat((prev) => ({ ...prev, ...rest }))
    } else if (isReadmore) {
      setCurrentChat((prev) => ({
        ...prev,
        chatMessages: _.transform(prev.chatMessages, (r, v, k) => {
          if (rest.messageId && k === rest.messageId) {
            r[k] = { ...v, isReadmore: isReadmore, domainName: rest.domainName }
          } else {
            r[k] = { ...v }
          }
        }),
      }))
    }
  }

  const handleLimitRequest = (err) => {
    if (_.includes([responseStatusCode.limitExceeded, responseStatusCode.methodNotAllowed], _.get(err, 'error_code'))) {
      setLimitModal({ isOpen: true, modalName: 'methodNotAllowModal', description: _.get(err, 'data.detail', "")})
      getSetting()
    } else {
      flash.showFlash(FLASH_TYPE.Error, err)
    } 
  }

  const handleTranslate = (i) => {
    const temp = { ...chatMessages }
    const item = _.find(temp, { id: i.id })
    if (!item) return
    item.origin = !item.origin
    setCurrentChat((prev) => ({
      ...prev,
      chatMessages: temp,
    }))

    if (audioRef.current?.currentId === item.id) {
      audioRef.current.needRefresh = true
    }

    if (!_.isEqual(item?.answer_trans?.text, item?.answer?.text)) {
      ChatbotApi.checkModulePermission(settingModuleMapping.translation, (err, res) => {
        if (err) {
          handleLimitRequest(err)
        }
      })
      return
    }

    if (!item.origin) {
      const payload = {
        id: item.id,
      }

      ChatbotApi.translate(payload, (err, res) => {
        if (!err && res?.data) {
          setCurrentChat((prev) => ({
            ...prev,
            chatMessages: _.transform(prev.chatMessages, (r, v, k) => {
              const answer_trans =
                k === i.id ? { type: 'answer', text: res.data } : v.answer_trans
              r[k] = { ...v, answer_trans }
            }),
          }))
        } else {
          handleLimitRequest(err)
        }
      })
    }
  }

  const handleSpeaking = (text, i) => {
    let isLoading = false
    const temp = _.transform(chatMessages, (r, v, k) => {
      if (v.speaker === SPEAKER_STATUS.loading) isLoading = true
      r[k] = { ...v, speaker: k === i.id ? v.speaker : SPEAKER_STATUS.pausing }
    })
    const item = _.find(temp, { id: i.id })

    if (!item || text === DEFAULT_ANSWER || isLoading) return

    if (item.speaker === SPEAKER_STATUS.playing) {
      // case playing, just pause, reset time
      item.speaker = SPEAKER_STATUS.pausing
      audioRef.current?.audio?.pause()
      audioRef.current.audio.currentTime = 0
    } else if (
      item.speaker === SPEAKER_STATUS.pausing &&
      audioRef.current?.currentId === item.id &&
      !audioRef.current?.needRefresh && audioRef.current?.audio.src !== ""
    ) {
      // case already have data, just play
      ChatbotApi.checkModulePermission(settingModuleMapping.textToSpeech, (err, res) => {
        if (!err && res) {
          item.speaker = SPEAKER_STATUS.playing
          audioRef.current?.audio?.play()
          setCurrentChat((prev) => ({
            ...prev,
            chatMessages: temp,
          }))
          return
        } else {
          handleLimitRequest(err)
        }
      })
    } else {
      // case don't have data, reset audioref and call api
      item.speaker = SPEAKER_STATUS.loading
      audioRef.current?.audio?.pause()
      audioRef.current.audio.currentTime = 0
      audioRef.current.currentId = item.id
      audioRef.current.needRefresh = false
    }

    setCurrentChat((prev) => ({
      ...prev,
      chatMessages: temp,
    }))

    if (item.speaker === SPEAKER_STATUS.loading) {
      const payload = {
        id: item.id,
        is_translated: !item.origin,
      }
      ChatbotApi.speak(payload, (err, res) => {
        if (!err && res.data) {
          setCurrentChat((prev) => ({
            ...prev,
            chatMessages: _.transform(prev.chatMessages, (r, v, k) => {
              r[k] = {
                ...v,
                speaker: k === i.id ? SPEAKER_STATUS.playing : v.speaker,
              }
            }),
          }))
          audioRef.current.audio.setAttribute('src', res.data)
          audioRef.current.audio.onended = () => {
            setCurrentChat((prev) => ({
              ...prev,
              chatMessages: _.transform(prev.chatMessages, (r, v, k) => {
                r[k] = {
                  ...v,
                  speaker: k === i.id ? SPEAKER_STATUS.pausing : v.speaker,
                }
              }),
            }))
          }
          audioRef.current.audio.play()
        } else {
          item.speaker = SPEAKER_STATUS.pausing
          handleLimitRequest(err)
        }
      })
    }
  }

  const handleClickPrompt = (prompt) => {
    if (is_reach_chat_limit) return
    setSelectedPrompt(prompt)
    setPrompts([])
  }

  const handleVote = (item) => {
    const payload = { is_liked: !item.isLiked }
    ChatbotApi.updateVote(item.id, { payload }, (err, res) => {
      if (!err) {
        setCurrentChat((prev) => ({
          ...prev,
          chatMessages: {
            ...prev.chatMessages,
            [item.id]: {
              ...prev.chatMessages[item.id],
              isLiked: res.data,
            },
          },
        }))
      }
    })
  }

  const handleChatting = (item) => {
    let obj = {
      id: DEFAULT_CHAT_ID,
      question: { type: 'question', text: '' },
      answer: { type: 'answer', text: DEFAULT_ANSWER },
      answer_trans: { type: 'answer', text: DEFAULT_ANSWER },
      origin: true,
      speaker: SPEAKER_STATUS.pausing,
      isSuggested: false,
      questionType: null,
      isLiked: false
    }
    updateChatMessages({ obj })
    const payload = { conversation_id: converId, question: item?.question?.text, parent_message_id: item.id, socket_id: socketSid.current }
    SocketApi.sendMessageChatting({ payload }, (err, res) => {
      if (err) {
        if (_.get(err, 'error_code') === responseStatusCode.limitExceeded) {
          setLimitModal({ isOpen: true, modalName: 'gosipModal', description: _.get(err, 'data.detail', "")})
          setCurrentChat((prev) => {
            const newChats = _.omit(prev.chatMessages, [DEFAULT_CHAT_ID])
            return { ...prev, chatMessages: newChats }
          })
          getSetting()
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
      } 
    })
  }

  const handleClickReadmoreBus = ({ messageId, domainName }) => {
    setReadmoreBusState((prev) => ({
      ...prev,
      isOpen: true,
      messageId,
      domainName,
    }))
  }

  const buildAdvSection = (item) => {
    const isClickedReadmore = _.get(item, 'isClickedReadmore', false)
    const advertisingData = _.get(item, 'advertising')
    const hasAdvertising = Boolean(advertisingData)
    const hasReadmore = _.get(item, 'isReadmore', false)
    const showReadMore = hasReadmore && !isClickedReadmore && !item?.isNoneAdv
  
    if (!hasAdvertising && !hasReadmore) {
      return null
    }
  
    return (
      <div className="advertising">
        <div className="flex divider">
          <div></div>
          <div></div>
          <div></div>
        </div>
        {hasAdvertising && (
          <div className="content w-100">
            <Markdown className="markdown-bussiness">
              {messages['queryMoreBusModal.businessNearYou'] + advertisingData}
            </Markdown>
          </div>
        )}
        {hasReadmore && (
          <div className="readmore-adv">
            {showReadMore ? (
              <div className="content w-100">
                <div
                  className="sub-content text-link c-pointer"
                  onClick={() =>
                    handleClickReadmoreBus({
                      messageId: item.id,
                      domainName: item.domainName,
                    })
                  }
                >
                  {hasAdvertising
                    ? messages['queryMoreBusButton.showMoreAction']
                    : messages['queryMoreBusButton.queryAction']}
                </div>
              </div>
            ) : item?.isNoneAdv ? (
              <div className="advertising">
                <div className="content w-100">{item.advInfo}</div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    )
  }

  const renderChatMessages = () => {
    if (_.isEmpty(chatMessages)) {
      return (
        <>
          <Welcome
            domainId={domainId}
            domains={domains}
            setCurrentChat={setCurrentChat}
            setPrompts={setPrompts}
          />
          <Suggestions
            prompts={prompts}
            handleClickPrompt={handleClickPrompt}
            isReachChatLimit={is_reach_chat_limit}
            domainId={domainId}
            domains={domains}
          />
        </>
      )
    }

    const buildAnswer = (text, item, isHideChatting = false) => {
      const handleCopy = (e) => {
        navigator.clipboard.writeText(text)
        const container = e.target.closest('div')
        if (container) {
          container.classList.add('active')
        }

        setTimeout(() => {
          container.classList.remove('active')
        }, 2000)
      }

      const getTranslateIcon = () => {
        const textLength = text.length
        const reduceText = (textLength / 10) >>> 0   // get 1/10 of current text 
        const checkStr = Math.max(reduceText, 3)   // make sure the check str > 3 
        return /[áàảãạâấầẩẫậăắằẳẵặéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵđ]/g.test(text.substr(textLength - checkStr)) ? 'translate' : 'translateVN'
      } 

      const audioIcons = {
        pausing: 'speaker',
        loading: 'audioLoading',
        playing: 'loading',
      }
      const audioTitle = {
        pausing: messages["actions.speakAloud"],
        loading: messages["actions.loading"],
        playing: messages["actions.stop"],
      }
      const className = 'flex p-6 c-pointer'
      const textDom = text === DEFAULT_ANSWER
        ? <div className="loading__dots">
          <span className="loading__dot"></span>
          <span className="loading__dot"></span>
          <span className="loading__dot"></span>
        </div>
        : <Markdown className="markdown-answer">{text}</Markdown>
      return (
        <li key={`message-answer-${item.id}`} className="answer">
          <div className="flex">
            <IconField wrapper={{ style: { paddingTop: '4px' } }} name="chatBot" />
            <div className="flex flex-col gap-4">
              <div className='main-answer-contents'>
                <div className="content w-100">
                  {textDom}
                </div>
                {buildAdvSection(item)}
              </div>
              {item.id !== DEFAULT_CHAT_ID && (
                <div className="actions flex gap-10">
                  <IconField
                    name="copy"
                    title={messages['actions.copy']}
                    wrapper={{
                      className,
                      onMouseUp: handleCopy,
                    }}
                  />
                  {isLogin() && (
                    <>
                      {showTranslateIcon && (
                        <IconField
                          name={getTranslateIcon()}
                          title={messages['actions.translate']}
                          wrapper={{
                            className: className,
                            onMouseUp: () => handleTranslate(item),
                          }}
                        />
                      )}
                      {showSpeakIcon && (
                        <IconField
                          name={audioIcons[item.speaker]}
                          title={audioTitle[item.speaker]}
                          className={
                            item.speaker !== SPEAKER_STATUS.loading
                              ? ''
                              : 'spin'
                          }
                          wrapper={{
                            className: `${item.speaker !== SPEAKER_STATUS.loading ? className : 'flex p-6'} ${item.speaker !== SPEAKER_STATUS.playing && isDisableTTS ? 'disabled-icon' : ''}`,
                            onMouseUp: () => handleSpeaking(text, item),
                          }}
                        />
                      )}
                      {(item.questionType) && (
                        <IconField
                          name={item.isLiked ? 'liked' : 'like'}
                          title={
                            item.isLiked
                              ? messages['actions.unlike']
                              : messages['actions.like']
                          }
                          wrapper={{
                            className: className,
                            onMouseUp: () => handleVote(item),
                          }}
                        />
                      )}
                      {(item.questionType == QUESTION_TYPE.premadeQuestion && !isHideChatting) && (
                        <IconField
                          name='chatting'
                          title={messages['actions.chat']}
                          wrapper={{
                            className: `${className} ${isDisableGoship ? 'disabled-icon' : ''}`,
                            onMouseUp: () => handleChatting(item),
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </li>
      )
    }

    const buildQuestion = (text, item) => (
      !_.isEmpty(text) && <li key={`message-question-${item.id}`} className="question">
        <div className="content">{text}</div>
      </li>
    )

    const isHideChatting = _.some(chatMessages, (message) => !message.question?.text)

    return (
      <div
        id="chat-list-messages"
        ref={listRef}
        className="chat-list-messages scroller"
      >
        <ul className="chat-messages">
          {_.map(chatMessages, (x, idx) => {
            const answer = x?.origin ? x?.answer : x?.answer_trans
            return [
              buildQuestion(x?.question?.text, x),
              buildAnswer(answer?.text, x, isHideChatting),
            ]
          })}
        </ul>
      </div>
    )
  }

  const MiniMenu = () => (
    <div>
      <ButtonField
        element={<IconField name="menu" />}
        onClick={() => setExpanding((prev) => !prev)}
        className="btn btn-icon p-0 mini-menu"
        title={
          expanding
            ? messages['navigation.closeSidebar']
            : messages['navigation.openSidebar']
        }
      />
    </div>
  )

  const renderWarning = () => {
    if (!is_reach_chat_limit) return <></>
    return (
      <div className="warning-limit">
        <div className="divider">
          <div />
          <div>
            <IconField name="warning" wrapper={true} />
            <span>{message}</span>
          </div>
          <div />
        </div>
      </div>
    )
  }

  return (
    <div className="site-chat">
      <div className="domain-header align-center">
        <MiniMenu />
        <SiteChatHeader totalQuestions={totalQuestions} converId={converId} setIsOpenSharingModal={setIsOpenSharingModal} />
      </div>
      <div ref={mainChatRef} className="main-chat">
        {renderChatMessages()}
        {renderWarning()}
        <ChatBox
          updateChatMessages={updateChatMessages}
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          inputRef={inputRef}
          setConvers={setConvers}
          loading={loading}
          setLoading={setLoading}
          sendMessage={sendMessage}
          prompt={selectedPrompt}
          handleLimitRequest={handleLimitRequest}
        />
        {readmoreBusState.isOpen && <AddMoreModal
          readmoreBusState={readmoreBusState}
          setReadmoreBusState={setReadmoreBusState}
          setCurrentChat={setCurrentChat}
        />}
        {isOpenSharingModal && <SharingModal oldLink={sharingLink} converId={converId} setIsOpenSharingModal={setIsOpenSharingModal} setCurrentChat={setCurrentChat}/>}
        {limitModal.isOpen && <ReachLimitModal
          limitModal={limitModal}
          setLimitModal={setLimitModal}
        />}
      </div>
    </div>
  )
}

export default SiteChat
