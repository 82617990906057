import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useSettings } from '../../../hooks/settingsContext'

const LoginWidget = ({ isShow, isShowGuidance }) => {
  const { messages } = useSettings()
  const navigate = useNavigate()

  let guidance = <></>
  if (isShowGuidance)
    guidance = (
      <div className="flex flex-col gap-8">
        <div className="text-primary large">
          {messages['loginWidget.header']}
        </div>
        <div className="text-secondary small">
          {messages['loginWidget.content']}
        </div>
      </div>
    )

  return (
    <div className="login-widget flex flex-col gap-16 just-cont-right">
      {isShow && guidance}
      {isShow && (
        <>
          <button
            className="btn btn-primary w-100 truncate-text"
            onClick={() => navigate('/signup')}
          >
            {messages['loginWidget.signup']}
          </button>
          <button
            className="btn btn-secondary w-100 truncate-text"
            onClick={() => navigate('/login')}
          >
            {messages['loginWidget.login']}
          </button>
        </>
      )}
    </div>
  )
}

export default LoginWidget
