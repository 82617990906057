import React from 'react'
import _ from 'lodash'

const Suggestions = ({
  prompts,
  handleClickPrompt,
  isReachChatLimit,
  domainId,
  domains,
}) => {
  const onRef = (node) => {
    if (!node) return
    const isOverflowing = node.clientWidth !== node.scrollWidth
    if (isOverflowing) node.setAttribute('title', node.innerHTML)
    else node.removeAttribute('title')
  }

  const renderSuggestionText = ({ text, anotherClass }) => {
    return (
      <>
        <span ref={onRef} className={`${anotherClass}`}>
          {text}
        </span>
      </>
    )
  }

  return (
    !_.isEmpty(prompts) && domainId && (
      <>
        <div className="suggestions-title">{_.find(domains, { id: domainId })?.name}</div>
        <div className="suggestions gray-scroll">
          {_.map(prompts, (c, key) => (
            <>
              {key > 0 ? <div className="divider"></div> : null}
              <div
                className="suggestion"
                key={key}
                onClick={() => handleClickPrompt(c.prompt)}
                style={{ cursor: isReachChatLimit && 'default' }}
              >
                <div className="content flex flex-col gap-4 ani-sink">
                  {renderSuggestionText({
                    key: key,
                    text: c.prompt,
                    anotherClass: 'description text-primary',
                  })}
                </div>
              </div>
            </>
          ))}
        </div>
      </>
    )
  )
}

export default Suggestions
