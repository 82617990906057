import React, { useState } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import { useFlash } from '../hooks/useFlash'
import { FLASH_TYPE, ACTION_OTP_TYPE } from '../common/constants'
import useFormBehavior from '../hooks/useFormBehavior'
import { useSettings } from '../hooks/settingsContext'
import { useAuth } from '../hooks/authProvider'
import IconField from '../components/iconField'
import { ChallengeAPI, UserApi } from '../api/admin'
import TermsPolicy from '../components/webChat/components/termsPolicy'

import './_signup.scss'

const ForgotPassword = () => {
  const flash = useFlash()
  const navigate = useNavigate()
  const { messages } = useSettings()
  const { setIsLogout } = useAuth()
  const [loading, setLoading] = useState(false)

  const fields = [
    {
      name: 'email',
      inputType: 'TextInput',
      labelError: messages[`signup.email.placeholder`],
      validators: ['required', 'maxLength', 'email'],
      maxLength: 320,
      className: 'email-input input-container',
      placeholder: messages['signup.email.placeholder'],
    },
  ]

  const { form, isFormValid, formData } = useFormBehavior({ fields })

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e)
    }
  }

  const handleSubmit = () => {
    if (isFormValid) {
      const emailAddress = formData['email']
      const data = { username: emailAddress, "is_admin_login": false }

      UserApi.checkValidUserName({ payload: data }, (err) => {
        if (!err) {
          navigate(`/reset-password/p?m=${emailAddress}`)
        } else {
          setLoading(false)
          flash.showFlash(FLASH_TYPE.Error, err)
        }
      })
    }
  }

  const handleBack = () => {
    navigate('/chat')
    setIsLogout(true)
  }

  return (
    <div className="site-layout">
      <div className="login-container flex flex-col">
        <div className="flex flex-col align-center gap-20">
          <IconField name="logoM" />
          <div className="main forgot-password flex flex-col align-center">
            <div className="top flex flex-col align-center">{messages['forgot.title']}</div>
            <div className="description flex flex-col align-center text-secondary">
              {messages['forgot.description']}
            </div>
            <div className="middle flex flex-col align-center">
              {form}
              <button
                className="btn btn-primary btn-forgot w-100"
                onClick={handleSubmit}
                disabled={!isFormValid || _.isEmpty(formData.email) || loading}
              >
                {messages['forgot.continue']}
              </button>
              <button
                className="btn btn-secondary btn-back w-100"
                onClick={handleBack}
              >
                {messages['forgot.back']}
              </button>
            </div>
          </div>
        </div>
        <TermsPolicy />
      </div>
    </div>
  )
}

export default ForgotPassword
