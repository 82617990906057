import { useContext, createContext, useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { setOption, getFingerprint } from '@thumbmarkjs/thumbmarkjs'

import { Auth, UserApi } from '../api/admin'
import { useFlash } from '../hooks/useFlash'
import { FLASH_TYPE, DEFAULT_CHAT_ID } from '../common/constants'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [isLogout, setIsLogout] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const detectGuestUser = () => {
      setOption('exclude', [
        'webgl',
        'system',
        'plugins.plugins',
        'fonts',
        'permissions',
      ])
      getFingerprint().then((fp) => {
        UserApi.getIdentity({ id: fp }, (err, res) => {
          if (res?.data) {
            getUser()
          }
        })
      })
    }

    Auth.checkAuth((err, res) => {
      if (!err && res) {
        const authenticated = _.get(res.data, "authenticated")
        if (authenticated) {
          getUser()
        } else {
          detectGuestUser()
        }
      } 
    })
  }, [])

  useEffect(() => {
    if (!_.isEmpty(user) && _.isEmpty(_.get(user, 'location'))) {
      const getGeoLocation = (cb) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const payload = {
              latitude: position?.coords?.latitude,
              longitude: position?.coords?.longitude,
            }
            UserApi.updateLocation(payload, (err, response) => {
              if (!_.isEmpty(response?.data)) {
                setUser((prev) => ({ ...prev, location: response?.data || {} }))
                return cb(true)
              } else {
                return cb(false)
              }
            })
          },
          () => cb(false)
        )
      }

      getGeoLocation(() => {})
    }
  }, [user])

  const getUser = (isSignout = false) => {
    UserApi.getUser((err, res) => {
      if (!err && res?.data) {
        const userInfo = _.get(res, 'data')
        setUser(userInfo)
        const role = _.get(userInfo, 'role.name')
        const paths = ['/terms-of-use', '/privacy-policy', '/sub-policy']

        if (_.find(paths, v => _.includes(pathname, v))) navigate(pathname)
        else if (_.includes(pathname, '/share/')) {
          if (pathname === '/share/') {
            navigate('/chat')
          } else {
            navigate(pathname)
          }
        }
        else if (role === 'guest' && !isSignout) navigate('/')
        else navigate('/chat')
      }
    })
  }

  const getSetting = (isSignout = false) => {
    if (user) {
      UserApi.getSetting((err, res) => {
        if (!err && res?.data) {
          const appInfo = _.get(res, 'data')
  
          setUser((prev) => ({
            ...prev,
            ...appInfo
          }))
        }
      })
    }
  }

  const loginAction = async (data, cb) => {
    Auth.login({ payload: data }, (err, res) => {
      if (!err) {
        getUser()
        setIsLogout(false)
      } else if (_.isFunction(cb)) {
        cb(err)
      }
    })
  }

  const logoutAction = () => {
    UserApi.logout({}, (err, res) => {
      if (!err) {
        setIsLogout(true)
        getUser(true)
        navigate('/chat')
      }
    })
  }

  const isLogin = () =>
    !_.isEmpty(user) && _.get(user, 'role.name', 'guest') !== 'guest'

  const value = useMemo(
    () => ({
      user,
      setUser,
      getUser,
      loginAction,
      logoutAction,
      isLogin,
      isLogout,
      setIsLogout,
      getSetting
    }),
    [user, isLogout]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext)
