import React, { useState, useEffect } from 'react'

import CommonModal from '../modal'
import { ChallengeAPI, UserApi } from '../../../api/admin'
import ChatbotApi from '../../../api/chatbot/chatbotAPI'
import { useSettings } from '../../../hooks/settingsContext'
import { useNavigate } from 'react-router-dom'
import { useFlash } from '../../../hooks/useFlash'
import { useAuth } from '../../../hooks/authProvider'
import { ACTION_OTP_TYPE, FLASH_TYPE } from '../../../common/constants'
import _ from 'lodash'

const AddMoreModal = ({
  readmoreBusState,
  setReadmoreBusState,
  setCurrentChat,
}) => {
  const { isOpen, messageId, domainName } = readmoreBusState
  const navigate = useNavigate()
  const auth = useAuth()
  const flash = useFlash()
  const [loading, setLoading] = useState(false)
  const { messages } = useSettings()
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState('')
  const [businessNum, setBusinessNum] = useState(0)

  useEffect(() => {
    ChatbotApi.getBusinessesNum({}, (err, res) => {
      if (!err && res) {
        setBusinessNum(res.data)
        if (res.data === 0) setError(messages['queryMoreBusModal.cantSendError'])
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
    })
  }, [])

  const handleCloseModal = () => {
    setReadmoreBusState((prev) => ({
      ...prev,
      isOpen: false,
      messageId: '',
      domainName: '',
    }))
    setInputValue('')
    setError('')
  }

  const handleReadMoreBusiness = () => {
    ChatbotApi.getMoreBusinesses(
      messageId,
      domainName,
      inputValue,
      (err, res) => {
        const getAdvertising = (responseData, preAdv) => {
          if (_.isEmpty(responseData) && _.isEmpty(preAdv))
            return {
              advInfo: messages['queryMoreBusModal.noMoreBusiness'],
              isNoneAdv: true,
            }
          return {
            advertising: !preAdv ? responseData : (preAdv += responseData), isClickedReadmore: true
          }
        }
        if (!err && res) {
          setCurrentChat((prev) => ({
            ...prev,
            chatMessages: {
              ...prev.chatMessages,
              [messageId]: {
                ...prev.chatMessages[messageId],
                ...getAdvertising(
                  res.data,
                  prev.chatMessages[messageId]?.advertising
                ),
              },
            },
          }))
          handleCloseModal()
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
      }
    )
  }

  const handleRenderElement = () => {
    return (
      <>
        <div className="title">{messages['queryMoreBusModal.title']}</div>
        <div className="flex flex-col gap-8">
          {businessNum !== 0 &&
            <input
              id="more-business-input"
              value={inputValue}
              onChange={handleInputChange}
              placeholder={messages['queryMoreBusModal.description']}
            ></input>
          }
          {error && <div className="error">{error}</div>}
        </div>
        <div className="action">
          <button
            className="btn btn-secondary rad-6"
            onClick={() => handleCloseModal()}
          >
            {messages['queryMoreBusModal.cancel']}
          </button>
          <button
            disabled={loading || !inputValue || error}
            className="btn btn-primary rad-6"
            onClick={handleReadMoreBusiness}
          >
            {messages['queryMoreBusModal.confirm']}
          </button>
        </div>
      </>
    )
  }

  const handleInputChange = (e) => {
    let value = e.target.value

    if (/[^0-9]/.test(value)) {
      value = value.replace(/[^0-9]/g, '');
    }

    setInputValue(value)

    if (value === '') {
      setError(messages['queryMoreBusModal.emptyError'])
    }
    else if (value < 0 || !(value >>> 0 === parseFloat(value))) {
      setError(messages['form.positive'])
    }
    else if (value < 1 || value > businessNum)  {
      setError(messages['queryMoreBusModal.typeValueError'].replace(
        '%s',
        businessNum))
    } else {
      setError('')
    }
  }

  return (
    <CommonModal
      isOpen={isOpen}
      id="more-business"
      name="more-business"
      noHeader
      noFooter
      modalClass="more-business-modal"
      element={handleRenderElement()}
      handleCancel={() => handleCloseModal()}
    />
  )
}

export default AddMoreModal
