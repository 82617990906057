import React from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

const BuildSubPolicyLink = ({ target = '_blank' }) => {
  const pathName = window.location.pathname  
  const items = {
    'disclaimer': {
      title: 'DISCLAIMER'
    },
    'content-policy': {
      title: 'CONTENT POLICY'
    },
    'eula': {
      title: 'END USER LICENSE AGREEMENT'
    },
    'cookie-policy': {
      title: 'COOKIE POLICY'
    },
    'refund-policy': {
      title: 'REFUND POLICY'
    }
  }

  const getTarget = (key) => {
    if (_.includes(pathName, key)) {
      return ''
    }
    return target
  }

  return <div className="flex flex-wrap just-cont-center w-100 gap-6">
    {_.map(items, (v, k) => {
      const link = <NavLink
        key={k}
        to={`/sub-policy/${k}`}
        target={getTarget(k)}
        className="text-primary small text-link"
      > {v.title} </NavLink>
      if (k !== 'disclaimer') return <React.Fragment key={k}>
        <div className="divider-rotate"></div>
        {link}
      </React.Fragment>
      else return link
    })}
  </div>
}

export default BuildSubPolicyLink
