import React, { useEffect } from 'react'
import _ from 'lodash'

import ChatbotApi from '../../../api/chatbot/chatbotAPI'
import { useSettings } from '../../../hooks/settingsContext'
import {
  ACTION_CHAT_TYPES,
  CONVERSATION_STATUS,
} from '../../../common/constants'
import CommonModal from '../modal'
import ButtonField from '../../buttonField'
import { useFlash } from '../../../hooks/useFlash'
import { FLASH_TYPE } from '../../../common/constants'

const ChatActionsModal = ({
  chatActions: { isOpen, id, type, title },
  setChatActions,
  convers,
  setConvers,
  currentChat,
  setCurrentChat,
  setIsOpenArchivedChats,
}) => {
  const { messages, setOpenSettings } = useSettings()
  const flash = useFlash()

  useEffect(() => {
    if (id && type === ACTION_CHAT_TYPES.archive) {
      const payload = { id }
      doSingle(`${type}Conversation`, payload)
    }
  }, [id, type])

  const handleCancel = () => {
    setChatActions((prev) => ({
      ...prev,
      isOpen: false,
      id: '',
      title: '',
      type: '',
    }))

    if (id && !_.some(convers?.list, (v) => _.some(v, { id })))
      setIsOpenArchivedChats(true)
    else if (!id) setOpenSettings(true)
  }

  const doSingle = (action, payload) => {
    ChatbotApi[action](payload, (err, res) => {
      if (!err && res?.data) {
        const tempList = _.reduce(
          convers?.list,
          (result, v, k) => {
            const items = _.filter(v, (i) => i.id !== id)
            if (!_.isEmpty(items)) result[k] = items
            return result
          },
          {}
        )
        setConvers((prev) => ({ ...prev, list: tempList }))
        if (id === currentChat.converId) {
          setCurrentChat((prev) => ({
            ...prev,
            converId: '',
            domainId: null,
            chatMessages: {},
            status: CONVERSATION_STATUS.active,
          }))
        }
        setChatActions((prev) => ({
          ...prev,
          isOpen: false,
          id: '',
          title: '',
          type: '',
        }))
        if (id && !_.some(convers?.list, (v) => _.some(v, { id })))
          setIsOpenArchivedChats(true)

        flash.showFlash(FLASH_TYPE.Success, res)
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
    })
  }

  const doAll = (action, payload) => {
    ChatbotApi[action](payload, (err, res) => {
      if (!err && res.data) {
        if (!id) setOpenSettings(true)
        setChatActions((prev) => ({ ...prev, isClearAll: true }))

        flash.showFlash(FLASH_TYPE.Success, res)
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {}
    if (id) {
      payload.id = id
      doSingle(`${type}Conversation`, payload)
    } else {
      doAll(`${type}Conversations`, payload)
    }
  }

  if (!isOpen) return <></>

  let titleModal = messages['settings.archiveChatsModal.title']
  let description = messages['settings.archiveChatsModal.description']
  let confirmText = messages['settings.archiveChatsModal.confirm']
  let className = 'btn btn-primary rad-6'
  if (id && title) {
    titleModal = messages['settings.deleteChatsModal.titleForOne']
    description = messages[
      'settings.deleteChatsModal.descriptionForOne'
    ].replace('%s', title)
    confirmText = messages['settings.deleteChatsModal.confirm']
    className = 'btn btn-warning rad-6'
  } else if (type === ACTION_CHAT_TYPES.delete) {
    titleModal = messages['settings.deleteChatsModal.title']
    description = messages['settings.deleteChatsModal.description']
    confirmText = messages['settings.deleteChatsModal.confirm']
    className = 'btn btn-warning rad-6'
  }

  const name = 'chat-actions'
  let modalProps = {
    isOpen: isOpen,
    id: name,
    name: name,
    modalClass: name,
    element: (
      <>
        <div className="title large">{titleModal}</div>
        <div title={description} className="content small">
          {description}
        </div>
        <div className="action">
          <ButtonField
            element={messages['settings.deleteChatsModal.cancel']}
            className={'btn btn-secondary rad-6'}
            onClick={handleCancel}
          />
          <ButtonField
            element={confirmText}
            className={className}
            onClick={handleSubmit}
          />
        </div>
      </>
    ),
    handleCancel: handleCancel,
    noHeader: true,
    noFooter: true,
  }

  return <CommonModal {...modalProps} />
}

export default ChatActionsModal
