import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { useFlash } from '../hooks/useFlash'
import { FLASH_TYPE } from '../common/constants'
import useFormBehavior from '../hooks/useFormBehavior'
import { useSettings } from '../hooks/settingsContext'
import IconField from '../components/iconField'
import { UserApi, ChallengeAPI } from '../api/admin'
import { useAuth } from '../hooks/authProvider'
import Confirmation from './confirmation'
import TermsPolicy from '../components/webChat/components/termsPolicy'

import './_signup.scss'

const ResetPassword = () => {
  const flash = useFlash()
  const navigate = useNavigate()
  const { messages } = useSettings()
  const { setIsLogout } = useAuth()
  const urlParams = new URLSearchParams(window.location.search)
  const email = urlParams.get('m')
  const challenge_code = urlParams.get('c')
  const [loading, setLoading] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    if (isSubmit) {
      onSubmit()
    }
  }, [isSubmit])

  const formFields = [
    {
      name: 'new_password',
      inputType: 'TextInput',
      placeholder: messages['reset.newPassword.placeholder'],
      labelError: messages['reset.newPassword.placeholder'],
      validators: ['required', 'password', 'maxLength'],
      maxLength: 255,
      className: 'pw-input input-container',
      type: 'password',
    },
    {
      name: 'confirm_password',
      inputType: 'TextInput',
      placeholder: messages['reset.confirmPassword.placeholder'],
      labelError: messages['reset.confirmPassword.placeholder'],
      validators: ['required', 'match'],
      relatedField: 'new_password',
      maxLength: 255,
      className: 'pw-input input-container',
      type: 'password',
    },
  ]

  const { form, isFormValid, formData, validateForm } = useFormBehavior({
    fields: formFields,
  })
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e)
    }
  }

  const onSubmit = () => {
    setIsSubmit(false)
    if (
      !isFormValid ||
      _.some(Object.values(formData).map((v) => _.isEmpty(v))) ||
      loading
    )
      return
    setLoading(true)

    if (isFormValid) {
      const emailAddress = urlParams.get('m')

      const data = {
        email: emailAddress,
        otp_type: 'forgot_password_otp',
      }

      ChallengeAPI.emailChallenges({ payload: data }, (err) => {
        if (!err) {
          setIsOpenConfirmation(true)
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
        setLoading(false)
      })
    }
  }

  const handleBack = () => {
    navigate('/chat')
    setIsLogout(true)
  }

  const handleSubmit = () => {
    validateForm()
    setIsSubmit(true)
  }

  if (isOpenConfirmation) {
    return (
      <Confirmation
        email={urlParams.get('m')}
        newPassword={formData['new_password']}
        type="forgot"
      />
    )
  }

  return (
    <div className="site-layout">
      <div className="login-container flex flex-col">
        <div className="flex flex-col align-center gap-20">
          <IconField name="logoM" />
          <div className="main forgot-password flex flex-col align-center">
            <div className="top flex flex-col align-center">{messages['reset.title']}</div>
            <div className="description flex flex-col align-center text-secondary">
              {messages['reset.description']}
            </div>
            <div className="middle flex flex-col align-center">
              {form}
              <button
                className="btn btn-primary btn-forgot w-100"
                onClick={handleSubmit}
                disabled={
                  !isFormValid ||
                  _.some(Object.values(formData).map((v) => _.isEmpty(v))) ||
                  loading
                }
              >
                {messages['reset.confirm']}
              </button>
              <button
                className="btn btn-secondary btn-back w-100"
                onClick={handleBack}
              >
                {messages['reset.cancel']}
              </button>
            </div>
          </div>
        </div>
        <TermsPolicy />
      </div>
    </div>
  )
}

export default ResetPassword
