import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { useSettings } from '../../../hooks/settingsContext'
import ChatbotApi from '../../../api/chatbot/chatbotAPI'
import CommonModal from '../modal'
import IconField from '../../iconField'
import { useFlash } from '../../../hooks/useFlash'
import {
  ACTION_CHAT_TYPES,
  FLASH_TYPE,
  SPEAKER_STATUS,
  CONVERSATION_STATUS,
} from '../../../common/constants'

const ArchivedChatsModal = ({
  isOpenArchivedChats,
  setIsOpenArchivedChats,
  setConvers,
  setChatActions,
  currentChat,
  setCurrentChat,
}) => {
  const { setOpenSettings, messages } = useSettings()
  const flash = useFlash()

  const [list, setList] = useState([])

  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (isOpenArchivedChats) {
      const value = _.trim(_.toLower(searchValue))
      ChatbotApi.getArchivedChats({}, (err, res) => {
        if (!err && res) {
          setList(
            _.map(_.sortBy(res?.data, ['date_created']), (v) => ({
              ...v,
              isShow: _.includes(_.toLower(v.conversation_name), value),
            }))
          )
        }
      })
    }
  }, [isOpenArchivedChats])

  const handleSearch = (e) => {
    e.preventDefault()
    const value = _.trim(_.toLower(_.get(e, 'target.value', '')))
    const search = _.debounce(
      (value) =>
        setList((prev) =>
          _.map(prev, (v) => ({
            ...v,
            isShow: _.includes(_.toLower(v.conversation_name), value),
          }))
        ),
      300
    )
    search(value)
    setSearchValue(_.get(e, 'target.value', ''))
  }

  const handleUnarchive = (e, id) => {
    e.preventDefault()
    const payload = {
      id: id,
    }
    ChatbotApi.unarchiveConversation(payload, (err, res) => {
      if (!err && res) {
        setList((prev) => _.filter(prev, (v) => v.id !== id))
        setConvers((prev) => ({ ...prev, needUpdate: true }))
        if (currentChat.converId === id) {
          setCurrentChat((prev) => ({
            ...prev,
            status: CONVERSATION_STATUS.active,
          }))
        }

        flash.showFlash(FLASH_TYPE.Success, res)
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
    })
  }

  const handleDelete = (e, item) => {
    setChatActions((prev) => ({
      ...prev,
      isOpen: true,
      id: item.id,
      title: item.conversation_name,
      type: ACTION_CHAT_TYPES.delete,
    }))
    setIsOpenArchivedChats(false)
  }

  const handleClose = () => {
    setIsOpenArchivedChats(false)
    setOpenSettings(true)
    setSearchValue('')
  }

  const selectConver = (e, item) => {
    e.preventDefault()
    const payload = {
      id: item.id,
    }
    ChatbotApi.getConversationDetail(payload, (err, res) => {
      if (!err && res?.data) {
        const chatMessages = _.reduce(
          res?.data?.conversation_history,
          (result, v) => {
            result[v.id] = {
              id: v.id,
              question: { text: v.question, type: 'question' },
              answer: { text: v.answer, type: 'answer' },
              answer_trans: { text: v.answer, type: 'answer' },
              advertising: v.advertising,
              origin: true,
              speaker: SPEAKER_STATUS.pausing,
              isLiked: v.is_liked,
              questionType: v.type,
              isReadmore: _.includes(v?.tracking, 'readmore', false),
              domainName: v.domain_name,
            }
            return result
          },
          {}
        )
        setCurrentChat((prev) => ({
          ...prev,
          converId: item.id,
          chatMessages,
          status: CONVERSATION_STATUS.archive,
          sharingLink:  _.get(res, 'data.share_link', '')
        }))
        setIsOpenArchivedChats(false)
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
    })
  }

  const onRef = (node) => {
    if (!node) return
    const isOverflowing = node.clientWidth !== node.scrollWidth
    if (isOverflowing) node.setAttribute('title', node.innerHTML)
    else node.removeAttribute('title')
  }

  const renderItems = () => {
    return (
      <div className="scrollbar archived-content">
        {_.map(list, (v, k) => {
          const date = new Date(v.date_created).toLocaleString('default', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          if (!v.isShow) return <React.Fragment key={k}></React.Fragment>
          return (
            <div key={k} className="archived-row flex">
              <div
                ref={onRef}
                onMouseUp={(e) => selectConver(e, v)}
                className="archived-name p-16 truncate-text c-pointer"
              >
                {v.conversation_name}
              </div>
              <div className="archived-date p-16">{date}</div>
              <div className="archived-actions flex p-16 align-center">
                <IconField
                  name="unarchive"
                  wrapper={{
                    className: `c-pointer`,
                    title: messages['archivedChat.unarchive'],
                  }}
                  onMouseUp={(e) => handleUnarchive(e, v.id)}
                />
                <IconField
                  name="deleteArc"
                  wrapper={{
                    className: `c-pointer`,
                    title: messages['archivedChat.delete'],
                  }}
                  onMouseUp={(e) => handleDelete(e, v)}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderChats = () => {
    if (_.isEmpty(list))
      return <div className="p-16">{messages['archivedChat.nothing']}</div>

    const renderTable = () => {
      if (!_.some(list, { isShow: true }))
        return <div className="p-16">{messages['archivedChat.noMatched']}</div>

      return (
        <div>
          <div className="archived-header flex align-center">
            <div className="archived-name p-16">
              {messages['archivedChat.name']}
            </div>
            <div className="archived-date p-16">
              {messages['archivedChat.dateCreated']}
            </div>
            <div className="archived-actions p-16">
              {messages['archivedChat.actions']}
            </div>
          </div>
          {renderItems()}
        </div>
      )
    }

    return (
      <div className="flex flex-col gap-24 w-100">
        <div className="search-field flex gap-4 align-center">
          <IconField
            name="lookup"
            wrapper={{ title: messages['archivedChat.search'] }}
          />
          <input
            className="input-text inactive"
            defaultValue={searchValue}
            placeholder={messages['archivedChat.search']}
            onKeyUp={handleSearch}
          />
        </div>
        {renderTable()}
      </div>
    )
  }

  return (
    <CommonModal
      isOpen={isOpenArchivedChats}
      id="archived-chats"
      name="archived-chats"
      title={messages['settings.archivedChats']}
      modalClass="archived-chats-modal"
      handleClose={handleClose}
      element={isOpenArchivedChats ? renderChats() : <></>}
      noFooter={true}
    />
  )
}

export default ArchivedChatsModal
