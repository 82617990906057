import React, { useEffect, useReducer, useRef } from 'react'
import _ from 'lodash'

import { useSettings } from '../../../hooks/settingsContext'
import ChatbotApi from '../../../api/chatbot/chatbotAPI'
import IconField from '../../iconField'
import { useFlash } from '../../../hooks/useFlash'
import {
  FLASH_TYPE,
  ACTION_CHAT_TYPES,
  SPEAKER_STATUS,
  CONVERSATION_STATUS,
} from '../../../common/constants'

const initState = {
  type: '',
  id: '',
  title: '',
}

const reducer = (state, action = {}) => {
  switch (action.type) {
    case 'rename':
      return { ...state, ...action }
    case 'view':
      return { ...state, ...action }
    case 'edit':
      return { ...state, ...action }
    default:
      return initState
  }
}

const History = ({
  convers,
  setConvers,
  currentChat,
  setCurrentChat,
  setChatActions,
  searchRef,
}) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const actionsRef = useRef(null)
  const inputRef = useRef(null)

  const { list } = convers
  const { messages } = useSettings()
  const flash = useFlash()

  const mapping_days = {
    0: messages['history.today'],
    1: messages['history.yesterday'],
    7: messages['history.daysAgo'].replace('%s', 7),
    30: messages['history.daysAgo'].replace('%s', 30),
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (actionsRef.current && !actionsRef.current.menu.contains(e.target)) {
        dispatch()
        actionsRef.current = null
      } else if (inputRef.current && !inputRef.current.contains(e.target)) {
        submitRename()
      }
    }

    if (inputRef.current) {
      inputRef.current.focus()
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [state])

  const selectConver = (item) => {
    if (currentChat.converId === item.id) return
    const payload = {
      id: item.id,
    }
    ChatbotApi.getConversationDetail(payload, (err, res) => {
      if (!err && res?.data) {
        const chatMessages = _.reduce(
          res?.data?.conversation_history,
          (result, v) => {
            result[v.id] = {
              id: v.id,
              question: { text: v.question, type: 'question' },
              answer: { text: v.answer, type: 'answer' },
              answer_trans: { text: v.answer, type: 'answer' },
              advertising: v.advertising,
              origin: true,
              speaker: SPEAKER_STATUS.pausing,
              isLiked: v.is_liked,
              questionType: v.type,
              isReadmore: _.includes(v?.tracking, 'readmore', false),
              domainName: v.domain_name,
            }
            return result
          },
          {}
        )
        setCurrentChat((prev) => ({
          ...prev,
          converId: item.id,
          domainId: item.domain_id,
          chatMessages,
          status: CONVERSATION_STATUS.active,
          sharingLink:  _.get(res, 'data.share_link', '')
        }))
      } else {
        flash.showFlash(FLASH_TYPE.Error, err)
      }
    })
  }

  const submitRename = () => {
    const name = _.trim(state.title)
    const payload = {
      id: state.id,
      payload: {
        name,
      },
    }

    let isChange = false
    _.forEach(convers?.list, (v) => {
      const item = _.find(v, { id: state.id })
      if (item) isChange = name && item.title !== name
    })

    isChange &&
      ChatbotApi.renameConversation(payload, (err, res) => {
        if (!err && res?.data) {
          const value = _.trim(_.toLower(_.get(searchRef.current, 'value', '')))
          const isShow = _.includes(_.toLower(res?.data?.title), value)
          const tempList = _.reduce(
            convers?.list,
            (result, v, k) => {
              let items = _.filter(v, (i) => i.id !== state.id)
              if (+k === 0) result[k] = _.concat(result[k], items)
              else if (!_.isEmpty(items)) result[k] = items
              return result
            },
            { 0: [{ ...res?.data, isShow }] }
          )

          setConvers((prev) => ({ ...prev, list: tempList }))

          flash.showFlash(FLASH_TYPE.Success, res)
        } else {
          flash.showFlash(FLASH_TYPE.Error, err)
        }
      })

    dispatch()
    inputRef.current = null
  }

  const handleRename = (e) => {
    e.preventDefault()

    dispatch({ type: 'rename' })
    actionsRef.current = null
  }

  const handleArChive = (e) => {
    e.preventDefault()
    setChatActions((prev) => ({
      ...prev,
      isOpen: false,
      id: state.id,
      title: state.title,
      type: ACTION_CHAT_TYPES.archive,
    }))
    dispatch()
    actionsRef.current = null
  }

  const handleDelete = (e) => {
    e.preventDefault()
    setChatActions((prev) => ({
      ...prev,
      isOpen: true,
      id: state.id,
      title: state.title,
      type: ACTION_CHAT_TYPES.delete,
    }))
    dispatch()
    actionsRef.current = null
  }

  const buildHeader = (header, key) => {
    return (
      <div key={key} className="flex flex-row divider">
        <div></div>
        <div>{header}</div>
        <div></div>
      </div>
    )
  }

  const buildActions = (i) => {
    const onClick = (e) => {
      e.preventDefault()
      if (state.id !== i.id) {
        dispatch({ type: 'view', id: i.id, title: i.title })
        const { x, y } = e.target.getBoundingClientRect()
        const width = 180
        const height = 136
        const padding = 8
        const centerPoint = 20
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        )
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        )
        const transX = vw > x + width + padding ? x : vw - width - padding
        const transY =
          vh > y + height + padding + centerPoint
            ? y + centerPoint
            : vh - height - padding - centerPoint
        actionsRef.current = {
          style: {
            left: 0,
            top: 0,
            transform: `translate(${transX}px, ${transY}px)`,
            width,
            backdropFilter: 'blur(5px)',
          },
          menu: e.target.closest('.menu'),
        }
      } else {
        dispatch()
        actionsRef.current = null
      }
    }
    return (
      <div className="menu">
        <IconField
          name="threeDots"
          wrapper={{ className: `c-pointer` }}
          onMouseUp={onClick}
        />
        {state.id === i.id && state.type === 'view' && (
          <div
            className="actions flex flex-col"
            style={actionsRef.current.style}
          >
            <div className="action flex gap-8" onMouseUp={handleRename}>
              <IconField name="rename" />
              <span>{messages['history.actions.rename']}</span>
            </div>
            <div className="action flex gap-8" onMouseUp={handleArChive}>
              <IconField name="archive" />
              <span>{messages['history.actions.archive']}</span>
            </div>
            <div className="action flex gap-8" onMouseUp={handleDelete}>
              <IconField name="delete" />
              <span>{messages['history.actions.delete']}</span>
            </div>
          </div>
        )}
      </div>
    )
  }

  const buildInside = (i) => {
    let element
    const prRef = (node) => {
      if (!node) return
      if (i.title && i.title.length > 32) {
        if (node.scrollWidth > node.clientWidth) node.classList.remove('gradient-text')
        else node.classList.add('gradient-text')
      }
    }

    const onRef = (node) => {
      if (!node) return
      const isOverflowing = node.clientWidth !== node.scrollWidth
      if (isOverflowing) node.setAttribute('title', node.innerHTML)
      else node.removeAttribute('title')
      if (i.title && i.title.length > 32) {
        if (node.scrollWidth > node.clientWidth) node.classList.add('gradient-text')
        else node.classList.remove('gradient-text')
      }
    }

    const handleChange = (e) => {
      if (e.keyCode === 13) {
        submitRename()
      }
    }
    if (state.id === i.id && state.type === 'rename') {
      element = (
        <input
          ref={inputRef}
          value={state.title}
          onChange={(e) => dispatch({ type: 'rename', title: e.target.value })}
          onKeyUp={handleChange}
          className="w-100"
        />
      )
    } else {
      element = (
        <>
          <div
            onMouseUp={() => selectConver(i)}
            className="flex flex-row gap-6 content"
            ref={prRef}
          >
            <IconField name="bubbleConver" wrapper={true} />
            <span ref={onRef} className="truncate-text small no-ellipsis">
              {i.title}
            </span>
          </div>
          {buildActions(i)}
        </>
      )
    }
    return element
  }

  const renderList = () => {
    return _.map(list, (v, k) => {
      const isShow = _.some(v, { isShow: true })
      const result = []
      if (isShow) {
        const text = mapping_days[k] ?? k
        const header = (
          <span className="truncate-text text-secondary">{text}</span>
        )
        result.push(buildHeader(header, k))
        _.forEach(v, (i) => {
          if (i.isShow) {
            const item = (
              <div
                key={i.id}
                className={`flex flex-row gap-8 item${currentChat?.converId === i.id ? ' active' : ''}`}
              >
                {buildInside(i)}
              </div>
            )
            result.push(item)
          }
        })
      } else {
        result.push(<React.Fragment key={k}></React.Fragment>)
      }
      return result
    })
  }

  const handleSearch = (e) => {
    e.preventDefault()
    const value = _.trim(_.toLower(_.get(e, 'target.value', '')))
    const search = _.debounce(
      (value) =>
        setConvers((prev) => {
          const tempList = _.transform(
            list,
            (r, v, k) => {
              r[k] = _.map(v, (i) => ({
                ...i,
                isShow: _.includes(_.toLower(i.title), value),
              }))
            },
            {}
          )
          return { ...prev, list: tempList }
        }),
      300
    )
    search(value)
  }

  const renderSearch = () => {
    const noMatched = () => {
      if (_.some(list, (v) => _.some(v, { isShow: true }))) {
        return <></>
      }
      return <div className="p-16">{messages['history.noMatched']}</div>
    }
    const hidden = _.isEmpty(list) ? ' hidden' : ''
    return (
      <div className={`search-history flex flex-col${hidden}`}>
        <div className="search-field flex gap-4 align-center">
          <IconField
            name="lookup"
            wrapper={{ title: messages['history.search'] }}
          />
          <input
            className="input-text"
            ref={searchRef}
            placeholder={messages['history.search']}
            onKeyUp={handleSearch}
          />
        </div>
        {noMatched()}
      </div>
    )
  }

  return (
    <div className="history flex flex-col">
      {renderSearch()}
      <div className="scrollbar overflow-y">{renderList()}</div>
    </div>
  )
}

export default History
