import React from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import TermsPolicy from '../components/webChat/components/termsPolicy'
import { useSettings } from '../hooks/settingsContext'
import IconField from '../components/iconField'

import './_signup.scss'

const HomePage = () => {
  const { messages } = useSettings()
  const navigate = useNavigate()

  return (
    <div className="site-layout">
      <div className="terms-container flex flex-col align-center">
        <div className="flex flex-col align-center gap-56">
          <div className="flex flex-col align-center gap-64">
            <IconField name="logoH" />
            <div className="flex flex-col align-center gap-16 w-100 p-20">
              <div className="term-note flex align-center gap-12 w-100 p-button">
                <IconField name="check" />
                {messages['terms.laws']}
              </div>
              <div className="term-note flex align-center gap-10 w-100 p-button">
                <IconField name="check" />
                {messages['terms.insurance']}
              </div>
              <div className="term-note flex align-center gap-10 w-100 p-button">
                <IconField name="check" />
                {messages['terms.others']}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-100 gap-16 p-20">
            <button
              className="btn btn-primary btn-verify w-100"
              onClick={() => navigate('/chat')}
            >
              {messages['terms.continue']}
            </button>
          </div>
        </div>
        <TermsPolicy />
      </div>
    </div>
  )
}

export default HomePage
