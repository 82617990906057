const toLocaleDateString = (dateStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  
    if (dateStr) {
      const date = new Date(dateStr)
      return date.toLocaleDateString('en-CA', options)
    }
  }
  
  export { toLocaleDateString }
  